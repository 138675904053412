
import {
  IonButton,
  IonInput,
  IonPage,
  IonContent,
  toastController,
  loadingController,
  modalController,
  IonFooter,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent } from "@vue/runtime-core";
import { keyOutline, callOutline, mail, arrowBack } from "ionicons/icons";
import validatePhone from "@/data/providers/user/validatePhone";
import sendValidationSMS from "@/data/providers/user/sendValidationSMS";
import { useStore } from "vuex";
import typesModule from "@/data/modules/typesModule";
import BasicHeader from "@/components/headers/BasicHeader.vue";

export default defineComponent({
  name: "ConfirmLogin",
  components: {
    IonButton,
    IonInput,
    IonPage,
    IonContent,
    IonFooter,
    IonToolbar,
    BasicHeader,
  },
  props: {
    phone: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    const store = useStore();

    const goBack = () => {
      modalController.dismiss();
    };

    const createToast = (message) => {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    };

    const openCodeSendToast = () => {
      createToast("Código reenviado");
    };

    const openCodeErrorToast = () => {
      createToast("Ocorreu um erro ao reenviar o código. Tente novamente!");
    };

    const validateCode = async (code) => {
      try {
        const response = await validatePhone(code);
        loadingController.dismiss();

        if (response.success) {
          store.dispatch(typesModule.actions.SET_PHONE_VERIFIED, 1);
          createToast("Telefone validado com sucesso");
          modalController.dismiss();
        } else {
          loadingController.dismiss();
          createToast("Ocorreu um erro ao validar o código");
        }
      } catch (error) {
        loadingController.dismiss();

        createToast("Código inválido. Tente novamente");
      }
    };

    return {
      keyOutline,
      callOutline,
      mail,
      arrowBack,
      goBack,
      openCodeSendToast,
      openCodeErrorToast,
      validateCode,
      createToast,
    };
  },
  data: () => ({
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    counter: 60,
  }),
  computed: {
    buttonName() {
      return this.counter <= 0
        ? "Reenviar o código"
        : `Aguarde ${this.counter}s`;
    },
  },
  watch: {
    counter: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.counter--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  methods: {
    nextInput(event, value) {
      if (event.key == "Control") {
        return;
      }

      if (event.ctrlKey && event.key == "v") {
        navigator.clipboard.readText().then((text) => {
          const textSplited = text.split("");
          this.code1 = textSplited[0] ?? "";
          this.code2 = textSplited[1] ?? "";
          this.code3 = textSplited[2] ?? "";
          this.code4 = textSplited[3] ?? "";
          this.code5 = textSplited[4] ?? "";
          this.$refs.input5.$el.setFocus();
        });
        return;
      }
      if (value == 1) {
        if (event.key == "Backspace") {
          return;
        }
        this.$refs.input2.$el.setFocus();
      } else if (value == 2) {
        if (event.key == "Backspace") {
          this.$refs.input1.$el.setFocus();
          return;
        }
        this.$refs.input3.$el.setFocus();
      } else if (value == 3) {
        if (event.key == "Backspace") {
          this.$refs.input2.$el.setFocus();
          return;
        }
        this.$refs.input4.$el.setFocus();
      } else if (value == 4) {
        if (event.key == "Backspace") {
          this.$refs.input3.$el.setFocus();
          return;
        }
        this.$refs.input5.$el.setFocus();
      } else if (value == 5) {
        if (event.key == "Backspace") {
          this.$refs.input4.$el.setFocus();
          return;
        }

        return;
      }
    },
    async validateCodePhone() {
      const codigo = `${this.code1}${this.code2}${this.code3}${this.code4}${this.code5}`;
      if (codigo.length < 5) {
        this.createToast("Preencha todos os campos!");
        return;
      }
      await this.showLoader("Confirmando login...");
      this.validateCode(codigo);
    },
    async showLoader(message) {
      const loader = await loadingController.create({
        message: message,
      });
      loader.present();
    },
    async sendCode() {
      this.showLoader("Enviando novamente...");
      try {
        await sendValidationSMS();
        this.counter = 60;

        loadingController.dismiss();
        return this.openCodeSendToast();
      } catch (error) {
        loadingController.dismiss();
        this.openCodeErrorToast();
      }
    },
    formatPhone(phone) {
      if (phone) {
        return "(" + phone.substring(0, 2) + ") " + phone.substring(2);
      }
      return;
    },
  },
});
