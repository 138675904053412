
import {
  IonButton,
  IonInput,
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonSelect,
  IonSelectOption,
  toastController,
  modalController,
  IonLabel,
  IonItem,
} from "@ionic/vue";
import SimpleHeader from "@/components/headers/SimpleHeader.vue";
import register from "@/services/register";
import ConfirmLoginVue from "./Login/ConfirmLogin.vue";
import TermsAndConditionsVue from "./TermsAndConditions.vue";
import { mask } from "vue-the-mask";

export default {
  name: "Login",
  directives: { mask },
  components: {
    SimpleHeader,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonItem,
  },
  props: {
    isModal: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const goConfirmRegister = async (email: string) => {
      const modal = await modalController.create({
        component: ConfirmLoginVue as any,
        componentProps: { email, isModal: true },
        cssClass: "custom-modal-full-transparent",
      });

      modal.onDidDismiss().then((response) => {
        if (response?.data?.login) {
          modalController.dismiss({ login: true });
        }
      });

      modal.present();
    };

    const goBack = () => {
      modalController.dismiss();
    };

    return {
      goBack,
      goConfirmRegister,
    };
  },
  data: () => ({
    name: "",
    phone: "",
    email: "",
    birth: "",
    gender: "",
  }),

  methods: {
    createToast(message) {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    },

    openDataNotFoundToast() {
      this.createToast("Todos os campos devem ser preenchidos!");
    },

    openInvalidEmailToast() {
      this.createToast("Informe um email válido");
    },

    async openTermsAndConditionsModal() {
      const modal = await modalController.create({
        component: TermsAndConditionsVue,
      });

      modal.present();
    },

    validEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    async goRegister() {
      if (
        this.name == "" ||
        this.phone == "" ||
        this.email == "" ||
        this.birth == "" ||
        this.gender == ""
      ) {
        this.openDataNotFoundToast();
      } else if (!this.validEmail(this.email)) {
        this.openInvalidEmailToast();
      } else {
        const res = await register(
          this.name.replace(/\w\S*/g, (w) =>
            w.replace(/^\w/, (c) => c.toUpperCase())
          ),
          this.phone,
          this.email,
          this.formatDate(this.birth),
          this.gender
        );
        if (res.success) {
          this.goConfirmRegister(this.email);
        } else {
          this.createToast(
            res.message || "Ops! Por favor, entre em contato com nossa equipe."
          );
        }
      }
    },
    formatDate(date) {
      const dateFormated = new Date(
        date.substr(6, 4),
        parseInt(date.substr(3, 2)) - 1,
        date.substr(0, 2)
      );

      return dateFormated;
    },
  },
};
