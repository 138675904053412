<template>
  <ion-card>
    <ion-skeleton-text
      class="product-image-skeleton"
      animated
    ></ion-skeleton-text>
    <div class="info-product">
      <ion-skeleton-text animated></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
    </div>
  </ion-card>
</template>

<script>
import { IonCard, IonSkeletonText } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: { IonCard, IonSkeletonText },
});
</script>

<style scoped>
ion-card {
  display: flex;
  border-radius: 20px;
  padding: 6px;
  box-shadow: none;
  border: 1px solid #ededed;
  margin: 5px 0;
}

.product-image-skeleton {
  height: 110px;
  width: 125px;
  border-radius: 15px;
}

.info-product {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-left: 15px;
}
</style>