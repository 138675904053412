
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonCard,
  IonCardContent,
  toastController,
  modalController,
} from "@ionic/vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import CouponCard from "@/components/cards/CouponCard.vue";
import CouponCardSkeleton from "@/components/skeletonComponents/CouponCardSkeleton.vue";
import { ticketOutline, close } from "ionicons/icons";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

import getUserCoupons from "@/data/providers/user/coupons/user-coupons";
import typesModule from "@/data/modules/typesModule";
import EstablishmentsByCouponPageVue from "@/views/Establishments/EstablishmentsByCouponPage.vue";

export default defineComponent({
  name: "Coupons",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    BasicHeader,
    CouponCard,
    CouponCardSkeleton,
  },
  setup() {
    const loadingRef = ref(true);
    const couponsAvailableRef = ref([]);
    const store = useStore();

    const getCoupons = async () => {
      loadingRef.value = true;

      const couponsData = await getUserCoupons(store.state.neighborhood.id);

      couponsAvailableRef.value = couponsData;

      loadingRef.value = false;
    };

    onMounted(getCoupons);

    return {
      loadingRef,
      couponsAvailableRef,
      ticketOutline,
      close,
    };
  },
  methods: {
    async selectCoupon(coupon) {
      if (coupon.many_establishments) {
        const modal = await modalController.create({
          component: EstablishmentsByCouponPageVue,
          componentProps: { coupon },
        });
        return modal.present();
      }
      if (coupon.establishment_id) {
        this.$store.dispatch(typesModule.actions.SET_COUPON_NAME, coupon.name);

        this.$router.push(`/estabelecimento/${coupon.establishment_id}`);
      }
      if (!coupon.establishment_id && !coupon.many_establishments) {
        const message = await this.$store.dispatch(
          typesModule.actions.SET_COUPON,
          coupon.name
        );

        const toast = await toastController.create({
          message: message,
          duration: 2000,
        });
        toast.present();

        if (history?.state?.back) {
          this.$router.back();
        } else {
          this.$router.push("/tabs/inicio");
        }
      }
    },
  },
});
