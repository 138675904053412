
import {
  IonContent,
  IonButton,
  IonInput,
  IonItem,
  IonIcon,
  IonRadio,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonLabel,
  IonCardContent,
  IonRadioGroup,
  modalController,
  toastController,
  IonSkeletonText,
} from "@ionic/vue";
import { ticketOutline, close } from "ionicons/icons";
import { defineComponent, onMounted, ref } from "vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";

import { useStore } from "vuex";

import getEstablishmentCoupons from "@/data/providers/establishments/establishment-coupons";
import typesModule from "@/data/modules/typesModule";

export default defineComponent({
  name: "CouponsModal",
  components: {
    IonContent,
    IonButton,
    IonInput,
    IonItem,
    IonIcon,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardContent,
    BasicHeader,
    IonSkeletonText,
  },
  setup() {
    const store = useStore();
    const establishmentId = store.state.cart.establishmentId;

    const loadingRef = ref(true);
    const couponsAvailableRef = ref([]);

    const getCoupons = async () => {
      loadingRef.value = true;
      const couponsData = await getEstablishmentCoupons(establishmentId);

      couponsAvailableRef.value = couponsData;

      loadingRef.value = false;
    };

    onMounted(getCoupons);

    return {
      loadingRef,
      couponsAvailableRef,
      ticketOutline,
      close,
    };
  },
  data: () => ({
    couponName: "",
    ticketApplied: "",
  }),
  methods: {
    async confirmValidateCoupon(coupon) {
      const message = await this.$store.dispatch(
        typesModule.actions.SET_COUPON,
        coupon || this.couponName
      );

      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      toast.present();

      if (this.$store.state.cart?.coupon?.name) {
        if (this.$store.state.cart.paymentMethod?.payment_method_id == 1) {
          this.$store.dispatch(
            typesModule.actions.SELECTED_PAYMENT_METHOD,
            undefined
          );
        }

        modalController.dismiss();
      }
    },
    closeModal() {
      modalController.dismiss();
    },
    formatDate(date) {
      const jsDate = new Date(date);

      return jsDate.toLocaleDateString("pt-BR");
    },
  },
});
