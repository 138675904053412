
import { useRouter } from "vue-router";
import { IonCard, IonImg } from "@ionic/vue";
export default {
  name: "FavoritetCard",
  components: { IonCard, IonImg },
  props: ["establishment"],
  setup(props: any) {
    const router = useRouter();

    const goEstablishment = (establishmentId) => {
      router.push(`/estabelecimento/${establishmentId}`);
    };

    return {
      goEstablishment,
    };
  },
};
