
import { IonIcon, IonButton } from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { useRouter } from "vue-router";

export default {
  name: "Header",
  components: { IonButton, IonIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
    cashBackValue: {
      type: Number,
      default: 0,
    },
    history: {
      type: Boolean,
      default: true,
    },
    hasGoBack: {
      type: Boolean,
      default: true,
    },
    hasCashback: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();

    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    const goTransactions = () => {
      router.push("/wallet/transactions");
    };

    return {
      arrowBack,
      goBack,
      goTransactions,
    };
  },
  methods: {
    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
  },
};
