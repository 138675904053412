
import {
  IonPage,
  IonButton,
  IonImg,
  IonList,
  IonContent,
  IonSpinner,
  IonIcon,
} from "@ionic/vue";
import { useStore } from "vuex";
import { onMounted } from "vue";
import { Ref, ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { arrowBack, bicycle, timeOutline } from "ionicons/icons";

import getOnlineEstablishmentInfoData from "@/data/providers/establishments/establishment-info";

export default {
  name: "EstablishmentInfo",
  components: {
    IonPage,
    IonButton,
    IonImg,
    IonList,
    IonContent,
    IonSpinner,
    IonIcon,
  },
  props: ["establishmentId"],

  setup(props: any) {
    const store = useStore();
    const router = useRouter();

    const loadingRef = ref(true);
    const establishmentInfoRef: Ref<any> = ref({});

    const neighborhoodState = store.state.neighborhood;

    const getEstablishmentInfoData = async () => {
      loadingRef.value = true;

      const establishmentInfoData = await getOnlineEstablishmentInfoData(
        neighborhoodState.id,
        props.establishmentId
      );

      establishmentInfoRef.value = establishmentInfoData;

      loadingRef.value = false;
    };

    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    onMounted(getEstablishmentInfoData);

    return {
      loadingRef,
      arrowBack,
      bicycle,
      timeOutline,
      establishmentInfoRef,
      goBack,
    };
  },

  methods: {
    moneyMask(value) {
      if (!value) {
        return;
      }
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
  },
};
