
import { IonIcon } from "@ionic/vue";
import { checkmarkCircle, checkmarkCircleOutline } from "ionicons/icons";

export default {
  components: {
    IonIcon,
  },
  props: {
    steps: {
      type: Object,
      default: null,
    },
    status: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return { checkmarkCircle, checkmarkCircleOutline };
  },
  data: function () {
    return {
      newSteps: null,
    };
  },
  mounted() {
    if (this.$props.status.id == 5) {
      const steps = this.$props.steps;
      this.newSteps = [steps[0], steps[steps.length - 1]];
    }
  },
  methods: {
    hourToString(stringDate: string) {
      const date = new Date(stringDate);

      const options: any = {
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleString("pt-br", options);
    },
  },
};
