<template>
  <ion-page>
    <ion-content>
      <header-with-balance
        title="Cupom"
        :has-go-back-modal="true"
        :has-go-back="false"
      />
      <div class="circular-border">
        <ion-img
          :src="`https://storage.googleapis.com/thelivery/coupons/${coupon.image}`"
        />
        <h3 class="titles">{{ coupon.title }}</h3>
        <p>{{ coupon.description }}</p>
        <ion-button
          shape="round"
          expand="full"
          color="primary"
          @click="showCode"
          >Ver cupom</ion-button
        >
        <h3 class="titles">Condições</h3>
        <div v-html="coupon.rules"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import {
  IonImg,
  IonContent,
  IonPage,
  loadingController,
  alertController,
} from "@ionic/vue";

import getOfflineCouponInfo from "@/data/providers/offline-coupons/offline-coupon-info";
import getOfflineCouponCode from "@/data/providers/offline-coupons/offline-coupon-code";

export default {
  name: "CouponsInfo",
  components: {
    HeaderWithBalance,
    IonImg,
    IonContent,
    IonPage,
  },
  props: {
    id: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      coupon: {},
    };
  },
  async mounted() {
    const coupon = await getOfflineCouponInfo(this.id);

    this.coupon = coupon;
  },
  methods: {
    async showCode() {
      if (!this.$store.state.user.token) {
        const alert = await alertController.create({
          header: "Código do cupon",
          message: "É necessário fazer login para receber o código do cupom;",
          buttons: [{ text: "Ok", handler: () => alert.dismiss() }],
        });

        alert.present();

        return;
      }

      const loading = await loadingController.create();

      loading.present();

      const { code } = await getOfflineCouponCode(this.coupon.id);

      loading.dismiss();

      const alert = await alertController.create({
        header: "Código do cupon",
        message: `Para receber o desconto, use o código: "${code}"`,
        buttons: [{ text: "Ok", handler: () => alert.dismiss() }],
      });

      alert.present();
    },
  },
};
</script>

<style scoped>
.circular-border {
  margin-top: 0;
}

ion-img::part(image) {
  border-radius: 15px;
}

.titles {
  font-size: 18px;
  font-weight: 500;
  margin: 12px 0 7px 0;
}

p {
  margin: 0;
  font-size: 14px;
  font-family: Roboto, Montserrat;
  color: var(--ion-color-medium);
}

ion-button {
  margin: 18px 0;
}
</style>