
import RatingStars from "@/components/RatingStars.vue";
import { IonAvatar, IonCard, IonCardContent } from "@ionic/vue";

export default {
  name: "ItemRatings",
  components: { RatingStars, IonAvatar, IonCard, IonCardContent },
  props: ["rating", "logo"],
  setup() {
    return {};
  },

  methods: {
    dateToString(stringDate: string) {
      const date = new Date(stringDate);
      return date.toLocaleDateString();
    },

    formatName(name) {
      const partsName = name.split(" ");
      return `${partsName[0]} ${partsName[1] ? partsName[1][0] + "." : ""}`;
    },
  },
};
