
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";

import PromotionProductCard from "@/components/PromotionProductCard.vue";
import EstablishmentCardSkeleton from "@/components/skeletonComponents/EstablishmentCardSkeleton.vue";

import getInitialPage from "@/data/providers/initial-page";
import Categories from "@/data/providers/categories";
import Promotions from "@/data/providers/promotions";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import typesModule from "@/data/modules/typesModule";

import { useStore } from "vuex";
import { watch } from "vue";

export default {
  name: "PromotionsPage",
  components: {
    HeaderWithBalance,
    PromotionProductCard,
    EstablishmentCardSkeleton,
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  setup() {
    const store = useStore();
    const loadingRef = ref(true);

    const promotionsRef = ref([]);
    const promotionsCategoriesRef = ref([]);
    const bannersRef = ref([]);
    const categoriesRef = ref([]);
    let cursor = 1;
    const neighborhoodState = store.state.neighborhood;

    store.dispatch(typesModule.actions.RENDERING_TABS, "/tabs/promotions");

    const getPromotions = async () => {
      loadingRef.value = true;

      const initialPageValues = await getInitialPage(neighborhoodState.id);

      bannersRef.value = initialPageValues.banners;

      const categoriesData = await Categories();

      categoriesRef.value = categoriesData;

      const promotionsData = await Promotions(neighborhoodState.id, 1);

      promotionsRef.value = promotionsData;

      promotionsCategoriesRef.value = promotionsData;

      cursor = 1;

      loadingRef.value = false;
    };

    const getNextPromotions = async (event: any) => {
      const promotionsData = await Promotions(neighborhoodState.id, ++cursor);
      promotionsRef.value.push(...promotionsData);
      event.target.complete();
    };

    onMounted(getPromotions);

    watch(neighborhoodState, () => getPromotions());

    return {
      loadingRef,
      bannersRef,
      promotionsRef,
      promotionsCategoriesRef,
      categoriesRef,
      cursor,
      getNextPromotions,
    };
  },
  data: function () {
    return {
      selectedCategories: [],
      currentCategories: [],
    };
  },

  methods: {
    isCategorySelected(category) {
      return this.selectedCategories[category.name];
    },
    openCoupons() {
      this.$router.push("/explore/coupons");
    },
    selectCategory(category) {
      if (this.isCategorySelected(category)) {
        delete this.selectedCategories[category.name];
        const index = this.currentCategories.indexOf(category.id);

        if (index > -1) {
          this.currentCategories.splice(index, 1);
        }

        this.filterCategory();
      } else {
        this.selectedCategories[category.name] = category;
        this.currentCategories.push(category.id);
        this.filterCategory();
      }
    },
    filterCategory() {
      if (this.currentCategories.length == 0) {
        this.promotionsCategoriesRef = this.promotionsRef;
      } else {
        this.promotionsCategoriesRef = this.promotionsRef.filter((category) =>
          this.currentCategories.includes(category.category_id)
        );
      }
    },
  },
};
