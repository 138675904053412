
import { arrowBack } from "ionicons/icons";
import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonImg,
  modalController,
} from "@ionic/vue";
import ModalGoStore from "@/components/modals/ModalGoStore.vue";
import getStores from "@/data/providers/explore/lomadee/stores";
import { onMounted } from "@vue/runtime-core";
import { Ref, ref } from "@vue/reactivity";
import { eventKeys, logEvent } from "@/services/analitics";

export default {
  name: "StoresPage",
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonImg,
  },
  setup() {
    const loadingRef = ref(true);
    const storesRef: Ref = ref([]);

    const getStoresList = async () => {
      loadingRef.value = true;

      const listStores = await getStores();

      storesRef.value = listStores;

      loadingRef.value = false;
    };

    onMounted(getStoresList);

    return {
      storesRef,
      loadingRef,
      arrowBack,
    };
  },
  methods: {
    ionViewDidEnter() {
      logEvent(eventKeys.OPEN_LOMADEE);
    },
    goBack() {
      if (history?.state?.back) {
        this.$router.back();
      } else {
        this.$router.push("/tabs/inicio");
      }
    },
    async openModal(store) {
      const modal = await modalController.create({
        component: ModalGoStore,
        cssClass: "modal-confirm-order",
        componentProps: {
          store: store,
        },
      });

      await modal.present();
    },
  },
};
