
import { defineComponent } from "@vue/runtime-core";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { close } from "ionicons/icons";

export default defineComponent({
  name: "TermsAndConditions",
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
  },
  setup() {
    const theliveryTerms = [
      {
        title: "1. DOS SERVIÇOS OFERECIDOS",
        clauses: [
          "1.1 Este termo regula o uso do serviço oferecido pelo site e / ou aplicativo TheLivery aos usuários, para possibilitar a escolha entre os restaurantes cadastrados e efetivar pedidos online de gêneros alimentícios para entrega em domicílio ou retirada no local, tudo de acordo com os cardápios disponibilizados no site / aplicativo.",

          "1.2 O serviço do TheLivery consiste em permitir, através do site e / ou aplicativo, que os usuários encaminhem pedidos de entrega de gêneros alimentícios.",
          "1.3 O usuário se declara ciente de que o serviço oferecido pelo TheLivery é referente à intermediação dos pedidos aos restaurantes, não abrangendo o preparo, embalagem, disponibilização e entrega física(viamotoboy ou outros meios) dos produtos, sendo tudo isso de responsabilidade integral do restaurante, aquem responde por reclamações acerca de quaisquer problemas relacionados a vício, defeito ou inexecução da feitura, preparo e entrega dos produtos.",
        ],
      },
      {
        title: "2. DO CADASTRO",
        clauses: [
          "2.1 Para utilização dos serviços descritos acima, o usuário deverá ter capacidade jurídica para atos cívis e prestar as informações exigidas no formulário de cadastro, assumindo total responsabilidade(cível e criminal) pela exatidão e veracidade de todas as informações fornecidas.",

          "2.1.1 Em caso de informações incorretas, inverídicas ou não confirmadas, o TheLivery se reserva ao direito de exigir documentação que comprove as informações ou ainda, bloquear o cadastro, impedindoassim o usuário de utilizar os serviços on - line até que a situação seja regularizada.O TheLivery se reserva ao direito de cancelar, bloquear e / ou excluir qualquer cadastro em caso de descumprimento de qualquer obrigação prevista no presente termo.",

          "2.2 Fica sob responsabilidade do usuário cadastrado a não divulgação a terceiros de seu login e senha, ficando totalmente responsável por qualquer solicitação de serviço que seja feita com seu respectivo cadastro.",
        ],
      },

      {
        title: "3. DAS OBRIGAÇÕES DO USUÁRIO",
        clauses: [
          "3.1 O usuário se responsabiliza pelo uso de seus dados de login e senha por terceiros, assumindo totalresponsabilidade pelas consequências de tais usos.",

          "3.2 É obrigação do usuário o fornecimento de informações verídicas e correta, além da total responsabilidade por todo conteúdo informado, mantendo atualizado o endereço de entrega dos produtos.",

          "3.3 O usuário se obriga, também, a pagar integralmente os produtos solicitados ou encomendados on - lineaos restaurantes através do aplicativo, quando esses são efetivamente entregues.O pagamento deve ser feito diretamente ao portador dos produtos encomendados.",

          "3.4 O USUÁRIO menor de 18 anos de idade está ciente de que não poderá encomendar e adquirir, em qualquer hipótese, produtos alcoólicos, responsabilizando - se pela correta informação de sua idade no item CADASTRO.",

          "3.5 O usuário concorda totalmente com os termos de privacidade do TheLivery.",
        ],
      },
      {
        title: "4. OBRIGAÇÕES DO TheLivery",
        clauses: [
          "4.1 Disponibilizar no site e / ou aplicativo do TheLivery, espaço virtual que permita ao usuário devidamente cadastrado efetivar pedidos de compra de gêneros alimentícios anunciados e comercializados pelos restaurantes.",

          "4.2 Proteger a confidencialidade de todas as informações fornecidas pelo usuário, contudo, não responderá pela reparação de prejuízos que possam ser derivados de apreensão e cooptação de dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas informações.",
        ],
      },
      {
        title: "5. MODIFICAÇÕES DESTE TERMO",
        clauses: [
          "5.1 O presente TERMO DE USO poderá, a qualquer tempo, ter seu conteúdo, ou parte dele, modificados para adequações e inserções, tudo com vistas ao aprimoramento dos serviços disponibilizados.",

          "5.2 As novas condições entrarão em vigência assim que sua veiculada no site, sendo possível ao usuáriomanifestar oposição a quaisquer dos termos modificados, desde que o faça por escrito, através do site www.thelivery.com.br, o que gerará o cancelamento de seu cadastro.",
        ],
      },
      {
        title: "6. CANAL DE COMUNICAÇÃO",
        clauses: [
          "6.1 O contato entre o usuário e o TheLivery se faz através do site www.thelivery.com.br e / ou e - mail, desde logo emprestando validade jurídica e efetividade a esses meios eletrônicos de trocas de informações.",
        ],
      },
      {
        title: "7. ACEITAÇÃO DO TERMO DE USO",

        clauses: [
          "7.1 O USUÁRIO declara ter lido, entendido e que aceita todas as regras, condições e obrigações estabelecidas no presente TERMO.",
        ],
      },
      {
        title: "8. FORO DE ELEIÇÃO",
        clauses: [
          "8.1 As partes elegem como competente para dirimir eventuais controvérsias que venham a surgir da interpretação e do cumprimento do presente TERMO o foro da Comarca de Vitória da Conquista - Ba.",
        ],
      },
    ];

    return { theliveryTerms, close };
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
  },
});
