
import {
  IonButton,
  IonInput,
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonFooter,
  IonToolbar,
  toastController,
  IonItem,
  IonLabel,
  IonSkeletonText,
} from "@ionic/vue";
import {} from "ionicons/icons";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { useRouter } from "vue-router";
import ShowProfile from "@/data/providers/user/user-info";
import EditProfile from "@/data/providers/user/edit-info";
import typesModule from "@/data/modules/typesModule";
import { mask } from "vue-the-mask";

export default {
  name: "Login",
  directives: { mask },
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonFooter,
    IonToolbar,
    IonItem,
    IonLabel,
    IonSkeletonText,
  },
  setup() {
    const router = useRouter();
    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    return {
      goBack,
    };
  },
  data: () => ({
    name: "",
    phone: "",
    phoneAlternative: "",
    email: "",
    birth: "",
    gender: "",
    loading: true,
  }),

  async mounted() {
    this.loading = true;

    const data = await ShowProfile();
    this.name = data.name;
    this.phone = data.phone;
    this.phoneAlternative = data.alternative_phone;
    this.email = data.email;
    this.birth = data.birth_date ? this.formatDate(data.birth_date) : "";
    this.gender = data.gender;

    this.loading = false;
  },

  methods: {
    createToast(message) {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    },

    formatDate(date, to = "br") {
      if (to == "br") {
        const dateFormated = new Date(date);
        return (
          ("0" + dateFormated.getDate()).slice(-2) +
          "/" +
          ("0" + (dateFormated.getMonth() + 1)).slice(-2) +
          "/" +
          dateFormated.getFullYear()
        );
      } else {
        const dateFormated = new Date(
          date.substr(6, 4),
          parseInt(date.substr(3, 2)) - 1,
          date.substr(0, 2)
        );

        return dateFormated;
      }
    },

    openDataNotFoundToast() {
      this.createToast("Todos os campos devem ser preenchidos!");
    },

    openInvalidEmailToast() {
      this.createToast("Informe um email válido");
    },

    validEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    alertName() {
      this.createToast(
        "Ops! Não é possível alterar o nome. Qualquer dúvida, fale conosco."
      );
    },

    async editProfile() {
      if (
        this.name == "" ||
        this.phone == "" ||
        this.email == "" ||
        this.birth == "" ||
        this.gender == ""
      ) {
        this.openDataNotFoundToast();
      } else if (!this.validEmail(this.email)) {
        this.openInvalidEmailToast();
      } else {
        try {
          const res = await EditProfile({
            name: this.name.replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            ),
            phone: this.phone.replace(/\D/g, ""),
            alternativePhone: this.phoneAlternative?.replace(/\D/g, ""),
            email: this.email,
            birthDate: this.formatDate(this.birth, "iso"),
            gender: this.gender,
          });

          this.createToast("Perfil editado com sucesso!");
          this.$store.dispatch(typesModule.actions.UPDATE_USER, res);
          this.$router.push("/tabs/perfil");
        } catch (error) {
          this.createToast(
            error.response?.data?.message ||
              "Ops! Por favor, entre em contato com nossa equipe."
          );
        }
      }
    },
  },
};
