
import {
  IonButton,
  IonInput,
  IonPage,
  IonContent,
  toastController,
  loadingController,
  modalController,
  IonItem,
  IonLabel,
  IonIcon,
  isPlatform,
  IonImg,
} from "@ionic/vue";
import { keyOutline, call, mail } from "ionicons/icons";
import SimpleHeader from "@/components/headers/SimpleHeader.vue";

import sendLoginMail from "@/services/sendLoginMail";
import loginWithGoogle from "@/services/loginWithGoogle";
import loginWithApple from "@/services/loginWithApple";
import typesModule from "@/data/modules/typesModule";
import ConfirmLogin from "./ConfirmLogin.vue";
import LoginWithPhone from "./LoginWithPhone.vue";
import LoginWithPassword from "./LoginWithPassword.vue";
import RegisterVue from "../Register.vue";
import ChatButton from "@/components/ChatButton.vue";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";

export default {
  name: "Login",
  components: {
    SimpleHeader,
    IonButton,
    IonInput,
    IonPage,
    IonContent,
    ChatButton,
    IonItem,
    IonLabel,
    IonIcon,
    IonImg,
  },
  props: {
    isModal: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    return {
      keyOutline,
      call,
      mail,
      isPlatform,
    };
  },
  data: () => ({
    email: null,
    loading: true,
  }),
  mounted() {
    (window as any).onSignIn = this.goLoginWithGoogle;
  },
  methods: {
    async goLogin(email: string) {
      const modal = await modalController.create({
        component: ConfirmLogin as any,
        componentProps: { email, isModal: true },
        cssClass: "custom-modal-full-transparent",
      });

      modal.onDidDismiss().then((response) => {
        if (response?.data?.login && this.$props.isModal) {
          modalController.dismiss({ login: true });
        }
      });

      modal.present();
    },

    async goLoginWithPhone() {
      const modal = await modalController.create({
        component: LoginWithPhone as any,
        componentProps: { isModal: true },
        cssClass: "custom-modal-full-transparent",
      });

      modal.onDidDismiss().then((response) => {
        if (response?.data?.login && this.$props.isModal) {
          modalController.dismiss({ login: true });
        }
      });

      modal.present();
    },

    async goLoginWithPassword() {
      const modal = await modalController.create({
        component: LoginWithPassword as any,
        componentProps: { isModal: true },
        cssClass: "custom-modal-full-transparent",
      });

      modal.onDidDismiss().then((response) => {
        if (response?.data?.login && this.$props.isModal) {
          modalController.dismiss({ login: true });
        }
      });

      modal.present();
    },

    async goRegister() {
      const modal = await modalController.create({
        component: RegisterVue as any,
        componentProps: { isModal: true },
        cssClass: "custom-modal-full-transparent",
      });

      modal.onDidDismiss().then((response) => {
        if (response?.data?.login && this.$props.isModal) {
          modalController.dismiss({ login: true });
        }
      });

      modal.present();
    },

    async sendCode(email: string) {
      this.showLoader();
      try {
        const response = await sendLoginMail(email);
        loadingController.dismiss();
        return response;
      } catch (error) {
        loadingController.dismiss();
        if (error.response?.data) {
          this.openEmailNotFoundToast(error.response?.data?.message);
        }
      }
      loadingController.dismiss();
    },
    async showLoader() {
      const loader = await loadingController.create({
        message: "Acessando sua conta...",
      });
      loader.present();
    },
    async goLoginWithGoogle() {
      const loader = await loadingController.create({});

      loader.present();

      try {
        const user = await GoogleAuth.signIn();
        loader.dismiss();
        this.showLoader();

        const loginResponse = await loginWithGoogle({
          googleId: user.id,
          name: user.name || `${user.givenName} ${user.familyName}`,
          email: user.email,
        });

        if (loginResponse?.token) {
          this.$store.dispatch(typesModule.actions.SET_USER, loginResponse);
          if (this.$props.isModal) {
            modalController.dismiss({ login: true });
          }
        } else {
          this.createToast(
            loginResponse?.message ||
              "Ops! Algo aconteceu de errado. Tente entrar por outra forma."
          );
        }
      } catch (_e) {
        loader.dismiss();
        loadingController.dismiss();
        this.createToast(
          "Ops! Algo aconteceu de errado. Tente entrar por outra forma."
        );
      }
      loadingController.dismiss();
    },
    async goLoginWithApple() {
      const loader = await loadingController.create({});
      loader.present();

      const options: SignInWithAppleOptions = {
        clientId: "br.com.thelivery",
        redirectURI: "",
        scopes: "name email",
      };

      SignInWithApple.authorize(options)
        .then(async (result: SignInWithAppleResponse) => {
          loader.dismiss();
          this.showLoader();

          const loginResponse = await loginWithApple({
            appleId: result.response.user,
            name: `${result.response.givenName} ${result.response.familyName}`,
            email: result.response.email,
          });

          if (loginResponse?.token) {
            this.$store.dispatch(typesModule.actions.SET_USER, loginResponse);
          } else {
            this.createToast(
              loginResponse?.message ||
                "Ops! Algo aconteceu de errado. Tente entrar por outra forma."
            );
          }

          loadingController.dismiss();
        })
        .catch(() => {
          loader.dismiss();
          loadingController.dismiss();
          this.createToast(
            "Ops! Algo aconteceu de errado. Tente entrar por outra forma."
          );
        });
    },
    goConfirmLogin() {
      if (this.validEmail(this.email)) {
        this.sendCode(this.email).then((res) => {
          res.success
            ? this.goLogin(this.email)
            : this.openEmailNotFoundToast();
        });
      } else {
        this.openInvalidEmailToast();
      }
    },
    validEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    openEmailNotFoundToast(message) {
      this.createToast(message);
    },
    openInvalidEmailToast() {
      this.createToast("Informe um email válido");
    },

    createToast(message) {
      toastController
        .create({
          message,
          duration: 2500,
        })
        .then((toast) => toast.present());
    },
  },
};
