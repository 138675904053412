
import { IonItem, IonCard, IonIcon } from "@ionic/vue";
import { defineComponent } from "@vue/runtime-core";
import { star, chatbubbleEllipses, chatbubbleOutline } from "ionicons/icons";

export default defineComponent({
  name: "OrderComponent",
  components: {
    IonItem,
    IonCard,
    IonIcon,
  },
  props: ["order"],
  setup() {
    return {
      star,
      chatbubbleOutline,
      chatbubbleEllipses,
    };
  },
  computed: {
    lastStatusHour() {
      const lastStatus = this.order.history.reduce(
        (prevStatus, currentStatus) =>
          new Date(prevStatus.date) > new Date(currentStatus.date)
            ? prevStatus
            : currentStatus
      );

      return this.hourToString(lastStatus.date);
    },
  },
  methods: {
    dateToString(stringDate: string) {
      const date = new Date(stringDate);
      return date.toLocaleDateString();
    },
    formatRating(rating) {
      return rating.toFixed(1).replace(".", ",");
    },
    hourToString(stringDate: string) {
      const date = new Date(stringDate);

      const options: any = {
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleString("pt-br", options);
    },
    async goOrderDetails(orderId) {
      this.$router.push(`/order-details/${orderId}`);
    },
  },
});
