
import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonRefresher,
  IonRefresherContent,
  IonFooter,
  modalController,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonToolbar,
  toastController,
  loadingController,
} from "@ionic/vue";

import QRCode from "qrcode";
import { Clipboard } from "@capacitor/clipboard";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import StatusHistory from "@/components/order/StatusHistory.vue";
import OrderDetailSkeleton from "@/components/skeletonComponents/OrderDetailSkeleton.vue";
import ChatModalPage from "@/views/Chat/ChatModalPage.vue";

import { Ref, ref } from "@vue/reactivity";
import {
  checkmarkCircle,
  callOutline,
  repeatOutline,
  star,
  time,
  chatbubblesOutline,
  chatbubbleOutline,
  chatbubbleEllipses,
  copySharp,
} from "ionicons/icons";

import { toggleChat } from "@/services/chat";
import EstablishmentChatService from "../../services/EstablishmentChat";
import typesModule from "@/data/modules/typesModule";
import getOnlineEstablishmentData from "@/data/providers/establishments/establishment";
import getOnlineProductData from "@/data/providers/product";

import OrderShow from "@/data/providers/orders/order-show";
import RatingModalPage from "../RatingModalPage.vue";

export default {
  name: "OrderDetails",
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonIcon,
    IonButton,
    IonFooter,
    IonRefresher,
    IonRefresherContent,
    StatusHistory,
    IonItem,
    IonLabel,
    IonCard,
    IonCardContent,
    IonToolbar,
    OrderDetailSkeleton,
  },
  props: {
    orderId: {
      type: String,
      default: undefined,
    },
  },
  setup(props: any) {
    const loadingRef = ref(true);
    const orderInfoRef: Ref<any> = ref({});

    let averageTimeValue1;
    let averageTimeValue2;
    let averageTime;

    const formatTime1Ref = ref("");
    const formatTime2Ref = ref("");

    const averageTimeMethod = () => {
      averageTime = orderInfoRef.value.average_time;

      const averageTimeValueArray = averageTime.split("-");

      const orderTimeInPreparation = orderInfoRef.value.history[0].date;

      const formatOrderTimeInPreparation1 = new Date(orderTimeInPreparation);
      const formatOrderTimeInPreparation2 = new Date(orderTimeInPreparation);

      formatOrderTimeInPreparation1.setMinutes(
        formatOrderTimeInPreparation1.getMinutes() +
          parseInt(averageTimeValueArray[0])
      );

      formatOrderTimeInPreparation2.setMinutes(
        formatOrderTimeInPreparation2.getMinutes() +
          parseInt(averageTimeValueArray[1])
      );

      const options: any = {
        hour: "2-digit",
        minute: "2-digit",
      };

      formatTime1Ref.value = formatOrderTimeInPreparation1.toLocaleString(
        "pt-br",
        options
      );
      formatTime2Ref.value = formatOrderTimeInPreparation2.toLocaleString(
        "pt-br",
        options
      );
    };

    const getOrderShow = async () => {
      loadingRef.value = true;

      const orderData = await OrderShow(props.orderId);

      orderInfoRef.value = orderData;

      averageTimeMethod();

      loadingRef.value = false;
    };

    const doRefresh = async (event) => {
      await getOrderShow();
      event.target.complete();
    };

    return {
      getOrderShow,
      orderInfoRef,
      doRefresh,
      checkmarkCircle,
      callOutline,
      loadingRef,
      repeatOutline,
      time,
      star,
      formatTime1Ref,
      formatTime2Ref,
      chatbubbleOutline,
      chatbubbleEllipses,
      chatbubblesOutline,
      toggleChat,
      copySharp,
    };
  },
  data() {
    return {
      hasUnreadMessages: false,
    };
  },
  computed: {
    qrcode() {
      let generateQR = "";
      QRCode.toDataURL(
        this.orderInfoRef.qrcode,
        { errorCorrectionLevel: "H" },
        function (_err, url) {
          generateQR = url;
        }
      );

      return generateQR;
    },
  },
  methods: {
    ionViewWillEnter() {
      EstablishmentChatService.startChatSocket();
    },
    ionViewWillLeave() {
      EstablishmentChatService.closeChatSocket();
    },
    async ionViewDidEnter() {
      EstablishmentChatService.getReadMessages().subscribe((data: string) => {
        if (data === this.orderInfoRef.id) {
          this.hasUnreadMessages = true;
        }
      });

      await this.getOrderShow();

      this.hasUnreadMessages = !this.orderInfoRef.user_read_message;
    },
    async orderRating() {
      const modal = await modalController.create({
        component: RatingModalPage,
        cssClass: "custom-modal-full-transparent",
        componentProps: { orderId: this.$props.orderId },
      });

      modal.present();

      modal.onDidDismiss().then(async (response) => {
        if (response.data?.rating) await this.getOrderShow();
      });
    },
    async clipboardPix() {
      await Clipboard.write({
        string: this.orderInfoRef.qrcode,
      });

      const toast = await toastController.create({
        message: "Pix Copia e Cola copiado.",
        duration: 2000,
      });
      toast.present();
    },
    formatRating(value) {
      return value.toFixed(1);
    },
    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
    dateToString(stringDate: string) {
      const date = new Date(stringDate);
      return date.toLocaleDateString();
    },
    hourToString(stringDate: string) {
      const date = new Date(stringDate);

      const options: any = {
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleString("pt-br", options);
    },
    async openRestaurantChat() {
      const modal = await modalController.create({
        component: ChatModalPage,
        componentProps: { order: this.orderInfoRef },
      });
      modal.present();
    },
    async remakeOrder() {
      const loader = await loadingController.create({
        message: "Criando o carrinho...",
      });
      loader.present();
      try {
        const establishmentData = await getOnlineEstablishmentData(
          this.orderInfoRef.address?.neighborhood_id,
          this.orderInfoRef.establishment.id
        );

        const infoEstablishment = {
          establishmentId: this.orderInfoRef.establishment.id,
          deliveryFee: establishmentData.deliveryFee?.delivery_fee,
          isWithdraw: establishmentData.withdraw,
          isOnlinePayment: establishmentData.online_payment,
          isPix: establishmentData.pix,
          isCashbackCampaign: establishmentData.cashbackCampaign,
          freeShippingBy: establishmentData.free_shipping_by,
          couponsAvailable: establishmentData.coupons_available,
          deliveryTime: establishmentData.average_time,
          allowCouponOrCashback: establishmentData.allow_coupon_or_cashback,
          minimumValue: establishmentData.minimum_value,
        };

        const productsIds = this.orderInfoRef.items.reduce(
          (acc, item) => [...new Set([...acc, item.product.id])],
          []
        );

        const products = await Promise.all(
          productsIds.map(async (id) => {
            return await getOnlineProductData(id);
          })
        );

        const newCart = this.orderInfoRef.items.map((item) => {
          const product: any = products.find(
            (product: any) => product.id == item.product.id
          );

          const optionsIds = item.options.reduce((acc, optionItem) => {
            const existentOptionIndex = acc.findIndex(
              (element) => element.id == optionItem.option.id
            );

            if (existentOptionIndex == -1) {
              acc.push({
                id: optionItem.option.id,
                suboptions: [
                  {
                    id: optionItem.suboption.id,
                    quantity: optionItem.quantity,
                  },
                ],
              });

              return acc;
            }

            acc[existentOptionIndex].suboptions.push({
              id: optionItem.suboption.id,
              quantity: optionItem.quantity,
            });

            return acc;
          }, []);

          const options = optionsIds.map((option) => {
            const selectedOption = product.options.find(
              (element) => element.id == option.id
            );

            const suboptions = option.suboptions.map((suboption) => {
              const selectedSuboption = selectedOption.suboptions.find(
                (element) => element.id == suboption.id
              );
              return selectedSuboption;
            });

            return {
              id: selectedOption.id,
              name: selectedOption.name,
              plus: selectedOption.plus,
              maxSelected: selectedOption.max_selected,
              mandadory: selectedOption.mandatory,
              suboptions,
            };
          });

          const productPrice = options.reduce((acc, option) => {
            if (option.plus == 0) {
              if (option.maxSelected == 0) {
                return (
                  acc +
                  option.suboptions.reduce(
                    (acc, suboption) => acc + suboption.price,
                    0
                  )
                );
              }
              const optionValue =
                product.type.establishment.split == "S"
                  ? option.suboptions.reduce(
                      (acc, suboption) => acc + suboption.price,
                      0
                    ) / option.suboptions.length
                  : option.suboptions.reduce(
                      (max, suboption) =>
                        suboption.price > max ? suboption.price : max,
                      0
                    );

              return acc + optionValue;
            } else {
              const optionValue = option.suboptions.reduce(
                (acc, suboption) => acc + suboption.price * suboption.quantity,
                0
              );

              return acc + optionValue;
            }
          }, 0);

          const totalPrice = productPrice * item.quantity;

          return {
            id: product.id,
            name: product.name,
            quantity: item.quantity,
            totalPrice: totalPrice,
            price: productPrice,
            note: item.note,
            simple: product.simple,
            options,
          };
        });

        this.$store.dispatch(typesModule.actions.INIT_CART, infoEstablishment);
        this.$store.dispatch(
          typesModule.actions.INIT_PAYMENT,
          infoEstablishment
        );

        newCart.forEach((product) => {
          this.$store.dispatch(typesModule.actions.ADD_PRODUCT, product);
        });

        await loader.dismiss();

        this.$router.push("/cart");
      } catch (_err) {
        await loader.dismiss();
        const toast = await toastController.create({
          message: "Ocorreu um erro ao montar o carrinho",
          duration: 2000,
        });
        toast.present();
      }
    },
  },
};
