
import { IonList, IonItem } from "@ionic/vue";
import ProductSelectMultipleComponent from "./ProductSelectMultipleComponent.vue";

export default {
  name: "SelectMultipleSuboption",
  components: { IonList, IonItem, ProductSelectMultipleComponent },
  props: ["option", "suboptions", "max_selected", "alreadySelected"],
  emits: ["selectSuboption"],
  data: () => ({
    selected: [],
    checked: 0,
  }),
  beforeMount() {
    if (this.alreadySelected) {
      this.selected = this.alreadySelected;
      this.alreadySelected.forEach((element) => {
        const suboption = this.suboptions.find(
          (suboption) => suboption.id == element.id
        );

        if (suboption) {
          suboption.alreadySelected = true;
        }
      });
      this.checked = this.alreadySelected.length;
    }
  },
  methods: {
    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
    checkSuboption(selectedSuboption, event) {
      if (event) {
        this.checked++;
      } else {
        this.checked--;
      }

      const index = this.selected.findIndex(
        (suboption) => suboption.id == selectedSuboption.id
      );

      if (index == -1) {
        this.selected.push(selectedSuboption);
      } else {
        this.selected.splice(index, 1);
      }

      this.$emit("selectSuboption", this.selected);
    },
  },
};
