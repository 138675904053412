import axios from "axios";
import store from "@/data/modules/index";

const instance = axios.create({
  baseURL: "https://api2.thelivery.com.br",
  //baseURL: "http://127.0.0.1:3333",
  headers: {
    Authorization: undefined,
  },
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = (store as any).state?.user?.token
    ? `bearer ${(store as any).state.user.token}`
    : undefined;

  return config;
});

export default instance;
