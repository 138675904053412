
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import NetworkError from "@/components/NetworkError.vue";

import PromotionProductCard from "@/components/PromotionProductCard.vue";
import PromotionCardSkeleton from "@/components/skeletonComponents/PromotionCardSkeleton.vue";

import getInitialPage from "@/data/providers/initial-page";
import Categories from "@/data/providers/categories";
import Promotions from "@/data/providers/promotions";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import typesModule from "@/data/modules/typesModule";

import { useStore } from "vuex";
import { watch } from "vue";
import { eventKeys, logEvent } from "@/services/analitics";

export default {
  name: "Explore",
  components: {
    HeaderWithBalance,
    PromotionProductCard,
    PromotionCardSkeleton,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    NetworkError,
  },
  setup() {
    const store = useStore();
    const loadingRef = ref(true);
    const occurredErrorRef = ref(false);
    const promotionsRef = ref([]);
    const promotionsCategoriesRef = ref([]);
    const bannersRef = ref([]);
    const categoriesRef = ref([]);
    let cursor = 2;
    const neighborhoodState = store.state.neighborhood;

    store.dispatch(typesModule.actions.RENDERING_TABS, "/tabs/promotions");

    const getPromotions = async () => {
      loadingRef.value = true;

      try {
        const initialPageValues = await getInitialPage(neighborhoodState.id);

        bannersRef.value = initialPageValues.banners;

        const categoriesData = await Categories();

        categoriesRef.value = categoriesData;

        const promotionsData = await Promotions(neighborhoodState.id, 1);

        promotionsRef.value = promotionsData;

        promotionsCategoriesRef.value = promotionsData;
        cursor = 2;
        occurredErrorRef.value = false;
      } catch (_error) {
        occurredErrorRef.value = true;
      } finally {
        loadingRef.value = false;
      }
    };

    const getNextPromotions = async (event: any) => {
      try {
        const promotionsData = await Promotions(neighborhoodState.id, cursor);
        promotionsRef.value.push(...promotionsData);

        cursor++;
      } finally {
        event.target.complete();
      }
    };

    onMounted(getPromotions);

    watch(neighborhoodState, () => getPromotions());

    return {
      loadingRef,
      bannersRef,
      promotionsRef,
      promotionsCategoriesRef,
      categoriesRef,
      cursor,
      getNextPromotions,
      getPromotions,
      occurredErrorRef,
    };
  },
  data: function () {
    return {
      selectedCategories: [],
      currentCategories: [],
    };
  },

  methods: {
    ionViewDidEnter() {
      document.title = `Promoções e descontos ${
        this.$store.state.neighborhood.cityName
          ? "em " + this.$store.state.neighborhood.cityName
          : ""
      } - TheLivery`;

      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          `Encontre as melhores promoções e descontos ${
            this.$store.state.neighborhood.cityName
              ? "em " + this.$store.state.neighborhood.cityName
              : ""
          } e receba dinheiro de volta (cashback).`
        );
      logEvent(eventKeys.OPEN_EXPLORE);
    },
    isCategorySelected(category) {
      return this.selectedCategories[category.name];
    },
    openCoupons() {
      this.$router.push("/explore/coupons");
    },
    openPromotions() {
      this.$router.push("/explore/promotions");
    },
    openStores() {
      this.$router.push("/explore/stores");
    },
    selectCategory(category) {
      if (this.isCategorySelected(category)) {
        delete this.selectedCategories[category.name];
        const index = this.currentCategories.indexOf(category.id);

        if (index > -1) {
          this.currentCategories.splice(index, 1);
        }

        this.filterCategory();
      } else {
        this.selectedCategories[category.name] = category;
        this.currentCategories.push(category.id);
        this.filterCategory();
      }
    },
    filterCategory() {
      if (this.currentCategories.length == 0) {
        this.promotionsCategoriesRef = this.promotionsRef;
      } else {
        this.promotionsCategoriesRef = this.promotionsRef.filter((category) =>
          this.currentCategories.includes(category.category_id)
        );
      }
    },
  },
};
