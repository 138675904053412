
import {
  IonPage,
  IonContent,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  modalController,
  onIonViewDidEnter,
  IonThumbnail,
  IonImg,
  toastController,
  IonSkeletonText,
} from "@ionic/vue";

import EstablishmentCard from "@/components/cards/EstablishmentCard.vue";
import EstablishmentCardSkeleton from "@/components/skeletonComponents/EstablishmentCardSkeleton.vue";

import BannerList from "@/components/BannerList.vue";
import SelectLocation from "@/components/SelectLocation.vue";
import BannerListSkeleton from "@/components/skeletonComponents/BannerListSkeleton.vue";
import FavoriteCardSlide from "@/components/FavoriteCardSlide.vue";
import NetworkError from "@/components/NetworkError.vue";
import FavoriteCardSkeleton from "@/components/skeletonComponents/FavoriteCardSkeleton.vue";
import CitiesModal from "../Cities.vue";
import HeaderEstablishmentsList from "@/components/headers/HeaderEstablishmentsList.vue";
import getOnlineEstablishments from "@/data/providers/online-establishments";
import getInitialPage from "@/data/providers/initial-page";
import { Ref, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { watch, defineComponent } from "vue";
import { useStore } from "vuex";
import { App } from "@capacitor/app";
import typesModule from "@/data/modules/typesModule";

export default defineComponent({
  name: "InitialPage",
  components: {
    EstablishmentCard,
    EstablishmentCardSkeleton,
    FavoriteCardSkeleton,
    FavoriteCardSlide,
    BannerListSkeleton,
    BannerList,
    IonContent,
    IonPage,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonGrid,
    IonRow,
    IonCol,
    IonRefresher,
    IonRefresherContent,
    HeaderEstablishmentsList,
    SelectLocation,
    NetworkError,
    IonThumbnail,
    IonImg,
    IonSkeletonText,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const updateTimeRef = ref(new Date());
    const establishmentsRef: Ref = ref([]);
    const loadingRef = ref(true);
    const occurredErrorRef = ref(false);
    const cursorRef = ref(1);
    const bannersRef = ref([]);
    const favoritesRef = ref([]);
    const categoriesRef = ref([]);
    const hasFavoritesRef = ref(false);
    const needToUpdateRef = ref(false);
    const bannerLinkRef = ref(undefined);

    const neighborhoodState = store.state.neighborhood;
    store.dispatch(typesModule.actions.RENDERING_TABS, undefined);

    const user = store.state.user;

    const openModalCities = async () => {
      const modal = await modalController.create({
        component: CitiesModal,
        cssClass: "my-custom-class",
        componentProps: {
          title: "New Title",
        },
      });
      return modal.present();
    };

    const getEstablishments = async () => {
      loadingRef.value = true;

      if (!neighborhoodState.id || !neighborhoodState.name) {
        openModalCities();
        loadingRef.value = false;
        return;
      }

      try {
        const initialPageValues = await getInitialPage(neighborhoodState.id);

        bannersRef.value = initialPageValues.banners;
        bannerLinkRef.value = initialPageValues.bannerLink;
        favoritesRef.value = initialPageValues?.favorites;
        hasFavoritesRef.value = !!initialPageValues?.favorites?.length;
        establishmentsRef.value = initialPageValues.establishments;
        categoriesRef.value = initialPageValues.categories;

        const userInfo = initialPageValues?.userInfo;
        if (userInfo) {
          store.dispatch(typesModule.actions.UPDATE_USER, userInfo);
        }

        const appOptions = initialPageValues?.app_options;
        if (appOptions) {
          store.dispatch(typesModule.actions.UPDATE_APP_OPTIONS, appOptions);
        }

        updateTimeRef.value = new Date();

        cursorRef.value = 2;
        occurredErrorRef.value = false;
      } catch (err) {
        occurredErrorRef.value = true;
      }
      loadingRef.value = false;
    };

    const getNextEstablishments = async (event: any) => {
      try {
        const newEstablishments = await getOnlineEstablishments(
          neighborhoodState.id,
          cursorRef.value
        );

        establishmentsRef.value.push(...newEstablishments);

        cursorRef.value = cursorRef.value + 1;
      } finally {
        event.target.complete();
      }
    };

    const goToEstablishment = (slug, categorySlug) => {
      const citySlug = store.state.neighborhood.citySlug;

      router.push(`/loja/${citySlug || "nome"}/${categorySlug}/${slug}`);
    };

    const doRefresh = async (event) => {
      await getEstablishments();
      event.target.complete();
    };

    onMounted(getEstablishments);

    onIonViewDidEnter(() => {
      document.title = `Delivery de comida, petshop e mercado ${
        store.state.neighborhood.cityName
          ? "em " + store.state.neighborhood.cityName
          : ""
      } - TheLivery`;

      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Entregamos tudo o que você precisa com dinheiro de volta (cashback). São milhares de opções de delivery: do pãozinho ao sushi e do sabonete a remédios."
        );
      if (needToUpdateRef.value) {
        getEstablishments();
      }
      needToUpdateRef.value = false;
    });

    watch(neighborhoodState, () => getEstablishments());

    App.addListener("appStateChange", ({ isActive }) => {
      const currentDate = new Date();

      const halfHourInMilliseconds = 1800000;

      if (
        isActive &&
        currentDate.getTime() >
          updateTimeRef.value.getTime() + halfHourInMilliseconds
      ) {
        getEstablishments();
      }
    });

    return {
      loadingRef,
      establishmentsRef,
      cursorRef,
      favoritesRef,
      hasFavoritesRef,
      bannersRef,
      categoriesRef,
      user,
      getNextEstablishments,
      doRefresh,
      neighborhoodState,
      goToEstablishment,
      getEstablishments,
      needToUpdateRef,
      occurredErrorRef,
      bannerLinkRef,
    };
  },
  watch: {
    "$store.state.user.token": function () {
      this.needToUpdateRef = true;
    },
  },
  methods: {
    slugify(category) {
      category = category.replace(/^\s+|\s+$/g, "");
      category = category.toLowerCase();

      const invalidChars = [
        "à",
        "á",
        "ä",
        "â",
        "è",
        "é",
        "ë",
        "ê",
        "ì",
        "í",
        "ï",
        "î",
        "ò",
        "ó",
        "ö",
        "ô",
        "ù",
        "ú",
        "ü",
        "û",
        "ñ",
        "ç",
        "·",
        "/",
        "_",
        ",",
        ":",
        ";",
      ];
      const validChars = [
        "a",
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "i",
        "i",
        "i",
        "o",
        "o",
        "o",
        "o",
        "u",
        "u",
        "u",
        "u",
        "n",
        "c",
        "-",
        "-",
        "-",
        "-",
        "-",
        "-",
      ];
      invalidChars.forEach((char, index) => {
        category = category.replace(
          new RegExp(invalidChars[index], "g"),
          validChars[index]
        );
      });

      category = category
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return category;
    },
    openLink(router) {
      if (router) {
        const link = this.$router.resolve({
          path: router,
        });
        if (link.matched.length > 0) {
          this.$router.push(router);
        } else {
          toastController
            .create({
              message:
                "Esta chamada não funcionará nesta versão. Por favor, atualize o aplicativo.",
              duration: 3000,
            })
            .then((toast) => toast.present());
        }
      }
    },
  },
});
