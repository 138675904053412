
import { IonCard, IonImg, modalController } from "@ionic/vue";
import Products from "../views/Establishments/Products.vue";
export default {
  name: "ProductCard",
  components: { IonCard, IonImg },
  props: ["product"],
  setup(props: any) {
    const getProduct = async () => {
      const modal = await modalController.create({
        component: Products,
        componentProps: { productId: props.product.id },
      });
      return modal.present();
    };

    return {
      getProduct,
    };
  },

  methods: {
    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
  },
};
