
import {
  IonPage,
  IonButton,
  IonContent,
  IonList,
  modalController,
  alertController,
  IonSkeletonText,
  IonCard,
  IonCardContent,
  IonImg,
} from "@ionic/vue";
import { ref } from "@vue/reactivity";

import { defineComponent, onMounted } from "vue";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { useStore } from "vuex";

import PaymentMethodsEstablishment from "@/data/providers/establishments/payment-methods-establishment";
import typesModule from "@/data/modules/typesModule";
import CustomModalMoney from "../CustomModalMoney.vue";
import AddCard from "../Profile/Wallet/AddCard.vue";
export default defineComponent({
  name: "PaymentMethods",
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonButton,
    IonList,
    IonSkeletonText,
    IonCard,
    IonCardContent,
    IonImg,
  },
  props: {
    payment: {
      type: Object,
      default: null,
    },
    walletUsed: {
      type: Number,
      default: null,
    },
  },
  setup() {
    const store = useStore();
    const paymentMethodsEstablishmentRef = ref([]);
    const loadingRef = ref(true);
    const establishmentId = store.state.cart.establishmentId;
    const isPaymentOnline = store.state.cart.isOnlinePayment;
    const isPix = store.state.cart.isPix;
    const cards = store.state.card.cards;
    const getPaymentMethodsEstablishment = async () => {
      loadingRef.value = true;
      const paymentMethodsEstablishmentData = await PaymentMethodsEstablishment(
        establishmentId
      );

      paymentMethodsEstablishmentRef.value = paymentMethodsEstablishmentData;

      loadingRef.value = false;
    };

    onMounted(getPaymentMethodsEstablishment);
    return {
      store,
      cards,
      paymentMethodsEstablishmentRef,
      isPaymentOnline,
      isPix,
      loadingRef,
    };
  },

  methods: {
    async addCard() {
      const modal = await modalController.create({
        component: AddCard,
      });

      modal.present();
    },

    selectedOnlinePayment(payment) {
      this.$store.dispatch(
        typesModule.actions.SELECTED_PAYMENT_METHOD,
        payment
      );

      modalController.dismiss("select");
    },

    async selectPaymentMethod(paymentMethod) {
      if (paymentMethod.paymentMethod.id == 1) {
        const alert = await alertController.create({
          header: "Troco",
          subHeader: "Precisará de troco?",
          buttons: [
            {
              text: "Não",
              handler: () => {
                const valueTotal =
                  this.$store.state.cart.totalPrice - this.$props.walletUsed;
                this.$store.dispatch(
                  typesModule.actions.SELECTED_PAYMENT_METHOD,
                  {
                    ...paymentMethod,
                    change: valueTotal,
                  }
                );
                modalController.dismiss("select");
                return;
              },
            },
            {
              text: "Sim",
              handler: () => {
                this.defineMoneyModal(paymentMethod);
                modalController.dismiss("select");

                return;
              },
            },
          ],
        });

        await alert.present();
      } else {
        this.$store.dispatch(
          typesModule.actions.SELECTED_PAYMENT_METHOD,
          paymentMethod
        );

        modalController.dismiss(true);
      }
    },

    async defineMoneyModal(paymentMethod) {
      const modal = await modalController.create({
        component: CustomModalMoney,
        cssClass: "custom-modal-full-transparent",
        componentProps: {
          title: "Troco",
          subtitle: "Precisará de troco para quanto?",
          inputs: [{ placeholder: "Troco para", type: "tel" }],
          paymentMethod: paymentMethod,
          totalValue:
            this.$store.state.cart.totalPrice - this.$props.walletUsed,
        },
      });
      await modal.present();
    },
  },
});
