
import {
  IonPage,
  IonContent,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonList,
  IonItem,
  modalController,
} from "@ionic/vue";

import EstablishmentCard from "@/components/cards/EstablishmentCard.vue";
import EstablishmentCardSkeleton from "@/components/skeletonComponents/EstablishmentCardSkeleton.vue";
import SelectLocation from "@/components/SelectLocation.vue";

import EstablishmentsListByCoupon from "@/data/providers/establishments/establishments-list-by-coupon";

import { Ref, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { watch, defineComponent } from "vue";
import { useStore } from "vuex";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import typesModule from "@/data/modules/typesModule";

export default defineComponent({
  name: "EstablishmentByCoupon",
  components: {
    EstablishmentCard,
    EstablishmentCardSkeleton,
    IonContent,
    IonPage,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonItem,
    BasicHeader,
    SelectLocation,
  },
  props: ["coupon"],
  setup(props: any) {
    const router = useRouter();
    const store = useStore();

    const establishmentsRef: Ref = ref([]);
    const loadingRef = ref(true);
    const cursorRef = ref(1);

    const neighborhoodState = store.state.neighborhood;

    const user = store.state.user;

    const getEstablishments = async () => {
      loadingRef.value = true;

      if (neighborhoodState?.id) {
        const listEstablishments = await EstablishmentsListByCoupon(
          neighborhoodState.id,
          props.coupon.id
        );

        establishmentsRef.value = listEstablishments;
        cursorRef.value = 2;
      }

      loadingRef.value = false;
    };

    const getNextEstablishments = async (event) => {
      const newEstablishments = await EstablishmentsListByCoupon(
        neighborhoodState.id,
        props.IdBanner,
        cursorRef.value
      );

      establishmentsRef.value.push(...newEstablishments);

      cursorRef.value += 1;
      event.target.complete();
    };

    const goToEstablishment = (id) => {
      store.dispatch(typesModule.actions.SET_COUPON_NAME, props.coupon.name);

      router.push(`/estabelecimento/${id}`);

      modalController.dismiss();
    };

    const doRefresh = async (event) => {
      await getEstablishments();
      event.target.complete();
    };

    onMounted(getEstablishments);

    watch(neighborhoodState, () => getEstablishments());

    return {
      loadingRef,
      establishmentsRef,
      cursorRef,
      user,
      doRefresh,
      neighborhoodState,
      goToEstablishment,
      getNextEstablishments,
    };
  },
  methods: {},
});
