
import {
  IonContent,
  IonButton,
  IonTextarea,
  IonCard,
  IonCardContent,
  modalController,
  toastController,
  IonSkeletonText,
  IonFooter,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent } from "vue";

import RatingStars from "@/components/RatingStars.vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import rateEstablishment from "@/data/providers/establishments/ratings/rate-establishment";
import getRatingsCriteria from "@/data/providers/establishments/ratings/get-rate-criteria";

export default defineComponent({
  name: "CustomModalMoney",
  components: {
    IonContent,
    IonButton,
    RatingStars,
    IonTextarea,
    BasicHeader,
    IonCard,
    IonCardContent,
    IonSkeletonText,
    IonFooter,
    IonToolbar,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    ratingsConfig: [],
  }),
  async mounted() {
    const ratingsCriteria = await getRatingsCriteria();
    this.ratingsConfig = ratingsCriteria;
  },
  methods: {
    updateRating(value, index) {
      this.ratingsConfig[index].value = value;
    },
    async sendRate() {
      if (this.ratingsConfig.some((rating) => rating.value === 0)) {
        this.createToast("É obrigatório avaliar todos os itens");
        return;
      }

      const ratings = this.ratingsConfig.reduce(
        (acc, cur) => ({ ...acc, [`${cur.name}`]: cur.value }),
        {}
      );

      const response = await rateEstablishment(this.$props.orderId, ratings);

      this.createToast(response.message);

      modalController.dismiss({ rating: true });
    },

    async createToast(message) {
      const toast = await toastController.create({
        message,
        duration: 2000,
      });

      return toast.present();
    },
  },
});
