
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { FreeMode, Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
export default {
  name: "BannerList",
  components: { Swiper, SwiperSlide },
  props: {
    banners: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      modules: [FreeMode, Pagination],
    };
  },

  methods: {
    async goEstablishments(banner) {
      if (banner.establishmentId) {
        this.$router.push(`/estabelecimento/${banner.establishmentId}`);
        return;
      }

      this.$router.push({
        name: `estabelecimentos-banner`,
        params: { IdBanner: banner.id, banner: banner.name },
      });
    },
  },
};
