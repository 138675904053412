
import { IonList, IonItem, IonButton } from "@ionic/vue";
import { add, remove } from "ionicons/icons";
import ProductCounterComponent from "@/components/ProductCounterComponent.vue";
export default {
  name: "SelectQuantitySuboption",
  components: { IonList, IonItem, ProductCounterComponent },
  props: ["suboptions", "alreadySelected"],
  emits: ["selectSuboption"],
  setup() {
    return { add, remove };
  },
  data: () => ({
    selected: [],
  }),
  beforeMount() {
    if (this.alreadySelected) {
      this.selected = this.alreadySelected;
      this.alreadySelected.forEach((element) => {
        const suboption = this.suboptions.find(
          (suboption) => suboption.id == element.id
        );

        if (suboption) {
          suboption.initialQuantity = element.quantity;
        }
      });
    }
  },
  methods: {
    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },

    checkQuantity(selectedSuboption, quantity) {
      const index = this.selected.findIndex(
        (suboption) => suboption.id == selectedSuboption.id
      );

      if (index === -1) {
        this.selected.push({ ...selectedSuboption, quantity });
      } else {
        this.selected[index].quantity = quantity;
        if (this.selected[index].quantity == 0) {
          this.selected.splice(index, 1);
        }
      }

      this.$emit("selectSuboption", this.selected);
    },
  },
};
