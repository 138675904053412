
import {
  IonPage,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonAvatar,
  IonContent,
  IonButton,
  IonBadge,
  IonList,
  IonItem,
  IonListHeader,
  IonFooter,
  IonToolbar,
  toastController,
  alertController,
  modalController,
  IonSkeletonText,
} from "@ionic/vue";
import ProductCard from "@/components/ProductCard.vue";
import NeighborhoodsModal from "@/views/Neighborhoods.vue";
import {
  bicycle,
  timeOutline,
  informationCircleOutline,
  star,
  arrowBack,
  heart,
  shareOutline,
  cart,
  flame,
} from "ionicons/icons";
import { useStore } from "vuex";
import { Ref, ref } from "@vue/reactivity";
import { useRouter } from "vue-router";

import getOnlineEstablishmentData from "@/data/providers/establishments/establishment";
import likeEstablishment from "@/data/providers/establishments/like-establishment";
import typesModule from "@/data/modules/typesModule";

import { Share } from "@capacitor/share";

import { logEvent, eventKeys } from "@/services/analitics";
import { Capacitor } from "@capacitor/core";
import ProductCardSkeleton from "@/components/skeletonComponents/ProductCardSkeleton.vue";

export default {
  name: "Establishment",
  components: {
    IonPage,
    IonIcon,
    IonContent,
    IonButton,
    IonAvatar,
    IonSegmentButton,
    IonSegment,
    IonList,
    IonItem,
    ProductCard,
    IonListHeader,
    IonFooter,
    IonToolbar,
    IonBadge,
    IonSkeletonText,
    ProductCardSkeleton,
  },
  props: {
    establishmentId: {
      type: String,
      default: null,
    },
    establishmentSlug: {
      type: String,
      default: null,
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const loadingRef = ref(true);
    const establishmentRef: Ref<any> = ref({});
    const likedRef: Ref<boolean> = ref(false);
    const colorRef = ref("light");
    const neighborhoodState = store.state.neighborhood;

    const goEstablishmentInfo = () => {
      router.push(`/estabelecimento/${establishmentRef.value.id}/info`);
    };

    const goRatings = () => {
      router.push(`/estabelecimento/${establishmentRef.value.id}/avaliacoes`);
    };

    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    return {
      loadingRef,
      establishmentRef,
      likedRef,
      colorRef,
      bicycle,
      timeOutline,
      informationCircleOutline,
      star,
      arrowBack,
      shareOutline,
      heart,
      goBack,
      goEstablishmentInfo,
      goRatings,
      cart,
      flame,
      neighborhoodState,
    };
  },

  computed: {
    cartPrice: function () {
      return this.$store.state.cart?.price;
    },
    cartTotalPrice: function () {
      return this.$store.state.cart?.totalPrice;
    },
  },
  methods: {
    slugify(category) {
      category = category.replace(/^\s+|\s+$/g, "");
      category = category.toLowerCase();

      const invalidChars = [
        "à",
        "á",
        "ä",
        "â",
        "è",
        "é",
        "ë",
        "ê",
        "ì",
        "í",
        "ï",
        "î",
        "ò",
        "ó",
        "ö",
        "ô",
        "ù",
        "ú",
        "ü",
        "û",
        "ñ",
        "ç",
        "·",
        "/",
        "_",
        ",",
        ":",
        ";",
      ];
      const validChars = [
        "a",
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "i",
        "i",
        "i",
        "o",
        "o",
        "o",
        "o",
        "u",
        "u",
        "u",
        "u",
        "n",
        "c",
        "-",
        "-",
        "-",
        "-",
        "-",
        "-",
      ];
      invalidChars.forEach((char, index) => {
        category = category.replace(
          new RegExp(invalidChars[index], "g"),
          validChars[index]
        );
      });

      category = category
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return category;
    },
    async ionViewWillEnter() {
      await this.getEstablishmentData();
      await this.initCart();
    },
    async getEstablishmentData() {
      this.loadingRef = true;

      const establishmentId =
        this.$route.params.establishmentSlug ||
        this.$route.params.establishmentId;

      const establishmentData = await getOnlineEstablishmentData(
        this.neighborhoodState.id,
        establishmentId
      );

      this.establishmentRef = establishmentData;

      this.likedRef = this.establishmentRef.liked;

      this.colorRef = this.likedRef ? "primary" : "light";

      this.loadingRef = false;

      document.title = `Delivery em ${establishmentData.name} - ${establishmentData.neighborhood.city.name} - TheLivery`;

      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          `Peça em ${establishmentData.name}. ${establishmentData.category}. ${establishmentData.neighborhood.name}. ${establishmentData.neighborhood.city.name}.`
        );

      logEvent(eventKeys.OPEN_RESTAURANT, {
        SLUG_ESTABELECIMENTO: establishmentData.slug,
        NOME_ESTABELECIMENTO: establishmentData.name,
        CASHBACK_ATIVO: establishmentData.cashbackCampaign?.id,
        BAIRRO_ID: this.neighborhoodState.id,
        BAIRRO_NOME: this.neighborhoodState.name,
        FAVORITO: establishmentData.liked,
      });
    },
    async initCart() {
      const infoEstablishment = {
        establishmentId: this.establishmentRef.id,
        deliveryFee: this.establishmentRef.deliveryFee?.delivery_fee,
        isWithdraw: this.establishmentRef.withdraw,
        isOnlinePayment: this.establishmentRef.online_payment,
        isPix: this.establishmentRef.pix,
        isCashbackCampaign: this.establishmentRef.cashbackCampaign,
        freeShippingBy: this.establishmentRef.free_shipping_by,
        couponsAvailable: this.establishmentRef.coupons_available,
        deliveryTime: this.establishmentRef.average_time,
        allowCouponOrCashback: this.establishmentRef.allow_coupon_or_cashback,
        minimumValue: this.establishmentRef.minimum_value,
      };
      if (this.establishmentRef.id == this.$store.state.cart.establishmentId) {
        this.$store.dispatch(
          typesModule.actions.UPDATE_CART_ESTABLISHMENT_INFO,
          infoEstablishment
        );
        return;
      }

      this.$store.dispatch(typesModule.actions.INIT_CART, infoEstablishment);
      this.$store.dispatch(typesModule.actions.INIT_PAYMENT, infoEstablishment);
    },
    async goCart() {
      if (!this.$store.state.neighborhood.id) {
        this.selectLocation(this.establishmentRef?.neighborhood?.city_id);
        return;
      }

      if (
        this.establishmentRef?.neighborhood?.city_id !=
        this.$store.state.neighborhood.cityId
      ) {
        const alert = await alertController.create({
          header: "Localização",
          message:
            "O estabelecimento não entrega nessa cidade, deseja mudar a localização?",
          buttons: [
            "Não",
            {
              text: "Sim",
              handler: () => {
                this.selectLocation(
                  this.establishmentRef?.neighborhood?.city_id
                );
              },
            },
          ],
        });

        alert.present();
        return;
      }
      if (!this.establishmentRef?.deliveryFee) {
        const alert = await alertController.create({
          header: "Localização",
          message:
            "O estabelecimento não entrega nesse bairro, deseja mudar a localização?",
          buttons: [
            "Não",
            {
              text: "Sim",
              handler: () => {
                this.selectLocation(
                  this.establishmentRef?.neighborhood?.city_id
                );
              },
            },
          ],
        });

        alert.present();
        return;
      }
      this.$router.push("/cart");
    },
    async selectLocation(cityId) {
      const establishmentId = this.establishmentRef.id;
      const modal = await modalController.create({
        component: NeighborhoodsModal,
        componentProps: { cityId, establishmentId },
        cssClass: "my-custom-class",
      });

      modal.onDidDismiss().then(({ data }) => {
        this.getEstablishmentData();
        data?.success && this.$router.push("/cart");
      });

      return modal.present();
    },
    async likeEstablishment() {
      const neighborhoodId = this.$store.state.neighborhood.id;
      const establishmentId = this.establishmentRef.id;

      if (this.colorRef === "light") {
        await likeEstablishment(neighborhoodId, establishmentId);
        this.colorRef = "primary";
        return;
      } else {
        await likeEstablishment(neighborhoodId, establishmentId);
        this.colorRef = "light";
      }

      logEvent(eventKeys.OPEN_RESTAURANT, {
        SLUG_ESTABELECIMENTO: this.establishmentRef.slug,
        NOME_ESTABELECIMENTO: this.establishmentRef.name,
        CASHBACK_ATIVO: this.establishmentRef.cashbackCampaign?.id,
        BAIRRO_ID: neighborhoodId,
        BAIRRO_NOME: this.$store.state.neighborhood.name,
        FAVORITO: this.establishmentRef.liked,
      });
    },
    scrollTo(elementId) {
      const element = document.getElementById(elementId);

      const content = this.$refs.content.$el;

      content.scrollByPoint(0, element.offsetTop, 1000);
    },

    onSegmentChange(id) {
      this.scrollTo(`category${id}`);
    },
    moneyMask(value) {
      if (!value) {
        return;
      }
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
    async shareEstablishment() {
      const url = `${this.$store.state.others.appOptions.base_url}/loja/${
        this.$store.state.neighborhood.citySlug
      }/${this.slugify(this.establishmentRef.category)}/${
        this.establishmentId
      }`;
      if (Capacitor.isNativePlatform()) {
        await Share.share({
          text: `Hey! Você já conhece o ${this.establishmentRef.name}? É simplesmente incrível :) Use o link abaixo e peça diretamente no TheLivery <3`,
          url,
        });
      } else {
        navigator.clipboard.writeText(url),
          toastController
            .create({
              message: "Endereço copiado",
              duration: 2000,
            })
            .then((toast) => toast.present());
      }
    },
  },
};
