import store from "@/data/modules/index";
import { isPlatform } from "@ionic/vue";
import { Intercom } from "@capacitor-community/intercom";

let open = false;

export async function updateUserNotificationToken() {
  const user = (store as any).state?.user;

  if (isPlatform("capacitor")) {
    await Intercom.updateUser({
      customAttributes: {
        isEstablishment: false,
        oneSignalId: user.notificationToken,
      },
    });
  } else {
    (window as any).Intercom("update", {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user.id,
      email: user.email,
      name: user.name,
      phone: user.phone,
      isEstablishment: false,
      oneSignalId: user.notificationToken,
    });
  }
}

export async function updateUser() {
  const user = (store as any).state?.user;

  if (isPlatform("capacitor")) {
    if (!user.token) {
      await Intercom.logout();
      await updateUserNotificationToken();
      return;
    }

    await Intercom.updateUser({
      name: user.name,
      email: user.email,
      userId: user.id,
      phone: user.phone,
      customAttributes: {
        isEstablishment: false,
        oneSignalId: user.notificationToken,
      },
    });
  } else {
    (window as any).Intercom("update", {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user.id,
      email: user.email,
      name: user.name,
      phone: user.phone,
      isEstablishment: false,
      oneSignalId: user.notificationToken,
    });
  }
}

export async function setUser() {
  const user = (store as any).state?.user;

  if (isPlatform("capacitor")) {
    if (!user.token) {
      await Intercom.registerUnidentifiedUser();
    } else {
      await Intercom.registerIdentifiedUser({
        userId: user.id,
        email: user.email,
      });
    }
  } else {
    (window as any).Intercom("update", {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user.id,
      email: user.email,
      name: user.name,
      phone: user.phone,
    });
  }

  updateUserNotificationToken();
}

export async function initChat() {
  if (!isPlatform("capacitor")) {
    const APP_ID = "a1wni0lg";

    (window as any).intercomSettings = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      app_id: APP_ID,
      // eslint-disable-next-line @typescript-eslint/camelcase
      hide_default_launcher: true,
    };

    (function () {
      const w = window as any;
      const ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        const d = document;
        const i: any = function () {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
          const s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + APP_ID;
          const x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
  }

  await setUser();
}

export async function logout() {
  if (isPlatform("capacitor")) {
    await Intercom.logout();
  } else {
    (window as any).Intercom("update", {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: undefined,
      email: undefined,
      name: undefined,
      phone: undefined,
    });
  }
}

export async function toggleChat() {
  if (isPlatform("capacitor")) {
    await Intercom.displayMessenger();
  } else {
    if (open) {
      (window as any).Intercom("hide");
      open = false;
    } else {
      (window as any).Intercom("show");
      open = true;
    }
  }
}

export default {
  initChat,
  updateUser,
  updateUserNotificationToken,
  toggleChat,
  logout,
};
