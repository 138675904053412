import axios from "@/utils/axios";

export default async function (neighborhoodId: number, page: number) {
  const response = await axios.get(
    `/neighborhood/${neighborhoodId}/promotions${page ? "?page=" + page : ""}`
  );
  const promotionsData = response.data;

  return promotionsData;
}
