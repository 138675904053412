import { Facebook } from "@awesome-cordova-plugins/facebook";
import { GoogleAnalytics } from "@awesome-cordova-plugins/google-analytics";
import { isPlatform } from "@ionic/vue";

export const eventKeys = {
  OPEN_RESTAURANT: "abriu_restaurante",
  OPEN_MEDALS: "abriu_medalhas",
  OPEN_PROMOTION: "abriu_promocao",
  OPEN_WALLET: "abriu_carteira",
  OPEN_WALLET_HISTORY: "abriu_historico_carteira",
  ADD_FAVORITE_RESTAURANT: "adicionou_restaurante_favorito",
  MAKE_PURCHASE: "compra_no_app",
  LOAD_ORDERS_OLDER: "carregou_pedidos_antigos",
  OPEN_ORDER: "abriu_pedido",
  OPEN_ORDER_OLDER: "abriu_pedido_antigo",
  CART_ABANDONED: "abandonou_o_carrinho",
  OPEN_BANNER_CAMPAIGN: "abriu_banner_campanha",
  OPEN_BANNER_ESTABLISHMENT: "abriu_banner_estabelecimento",
  OPEN_EXPLORE: "abriu_explorar",
  OPEN_OFFLINE_COUPONS: "abriu_cupons_offline",
  OPEN_LOMADEE: "abriu_lomadee",
};

export async function logEvent(key, payload?) {
  await Facebook.logEvent(key, payload);
  if (isPlatform("capacitor")) {
    await GoogleAnalytics.trackView(key);
  }
}
