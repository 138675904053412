
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  toastController,
  modalController,
  alertController,
} from "@ionic/vue";
import userInfo from "@/data/providers/user/user-info";
import sendValidationSMS from "@/data/providers/user/sendValidationSMS";
import { Ref, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import ProfileSkeleton from "@/components/skeletonComponents/ProfileSkeleton.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import typesModule from "@/data/modules/typesModule";
import ValidatePhoneVue from "../Login/ValidatePhone.vue";

export default {
  name: "ProfileInfo",
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    ProfileSkeleton,
  },
  setup() {
    const router = useRouter();
    const infoRef: Ref<any> = ref({});
    const loadingRef = ref(true);
    const store = useStore();

    const createToast = (message) => {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    };

    const getUserInfo = async () => {
      loadingRef.value = true;

      const userInfoData = await userInfo();
      infoRef.value = userInfoData;

      store.dispatch(typesModule.actions.UPDATE_USER, userInfoData);

      loadingRef.value = false;
    };

    const logoff = async () => {
      const alert = await alertController.create({
        subHeader: "Quer mesmo sair?",
        buttons: [
          {
            text: "Não",
            role: "cancel",
          },
          {
            text: "Sim",
            handler: async () => {
              store.dispatch(typesModule.actions.LOGOFF);
            },
          },
        ],
      });
      await alert.present();
    };

    const openFaq = async () => {
      router.push("/faq");
    };

    const openSessionsActive = async () => {
      router.push("/active-sessions");
    };

    const openWallet = async () => {
      router.push("/wallet");
    };

    const address = async () => {
      router.push("/address");
    };

    const coupons = async () => {
      router.push("/cupons");
    };

    const raffles = async () => {
      router.push("/sorteios");
    };

    const editProfile = async () => {
      router.push("/edit-profile");
    };

    const sendCodePhone = async () => {
      const validationPhone = await sendValidationSMS();
      createToast(validationPhone.message);

      const modal = await modalController.create({
        component: ValidatePhoneVue as any,
        componentProps: { phone: infoRef.value.phone },
        cssClass: "custom-modal-full-transparent",
      });

      modal.present();
    };
    onMounted(getUserInfo);

    return {
      loadingRef,
      infoRef,
      openFaq,
      openSessionsActive,
      sendCodePhone,
      openWallet,
      address,
      coupons,
      raffles,
      logoff,
      editProfile,
    };
  },
  computed: {
    name() {
      return this.$store.state.user.name;
    },
    email() {
      return this.$store.state.user.email;
    },
    phoneVerified() {
      return this.$store.state.user.phoneVerified;
    },
    cashbackValue() {
      return this.$store.state.user.cashbackValue;
    },
  },
};
