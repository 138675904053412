import axios from "@/utils/axios";

export default async function (page?: number) {
  const response = await axios.get(
    `/order/by-status${page ? "?page=" + page : ""}`
  );

  const orders = response.data;

  return orders;
}
