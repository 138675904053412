
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
} from "@ionic/vue";
import {
  homeOutline,
  triangle,
  personOutline,
  list,
  search,
} from "ionicons/icons";

export default {
  name: "Tabs",
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    return {
      homeOutline,
      triangle,
      personOutline,
      list,
      search,
    };
  },
  methods: {
    goTo(href) {
      this.$router.push(href);
    },
  },
};
