<template>
  <ion-content>
    <basic-header title="Números da Sorte" />
    <div class="circular-border">
      <ion-card v-if="luckyNumbers.length == 0">
        <ion-card-content>
          Ops! Você ainda não tem números da sorte neste sorteio.
        </ion-card-content>
      </ion-card>
      <ion-list v-for="number in luckyNumbers" :key="number.id">
        <ion-item class="no-padding">
          <div class="left">
            <h3>#{{ number.coupon }}</h3>
            <span>Pedido #{{ number.order_id }}</span>
          </div>
          <p>{{ formatDate(number.date) }}</p>
        </ion-item>
      </ion-list>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonContent,
  IonList,
  IonItem,
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import LuckyNumbers from "@/data/providers/raffles/tickets";
export default defineComponent({
  name: "LuckyNumbersModal",
  components: {
    IonContent,
    BasicHeader,
    IonList,
    IonItem,
    IonCard,
    IonCardContent,
  },
  props: {
    raffle: {
      type: Number,
      default: undefined,
    },
  },
  data: () => ({
    luckyNumbers: Object,
    loading: true,
  }),
  async mounted() {
    this.loading = true;
    this.luckyNumbers = await LuckyNumbers(this.$props.raffle);
    this.loading = false;
  },
  methods: {
    formatDate(date) {
      const jsDate = new Date(date);

      return jsDate.toLocaleDateString("pt-BR");
    },
  },
});
</script>

<style scoped>
ion-card {
  box-shadow: none;
  border: 1px solid #ebebeb;
  margin: 0;
}
ion-card-content {
  font-size: 12px;
}
ion-list {
  padding: 0;
}
ion-item {
  display: flex;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  padding: 8px 12px;
  margin-bottom: 6px;
}
.left {
  display: flex;
  flex-direction: column;
  flex: 1;
}
h3 {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}
span {
  font-size: 12px;
  margin-top: 2px;
}
p {
  font-size: 10px;
  margin: 2px 0 0 0;
}
</style>