
import HeaderWallet from "@/components/headers/HeaderWallet.vue";
import CreditCardCard from "@/components/CreditCardCard.vue";

import AddCardModal from "../Wallet/AddCard.vue";
import {
  IonPage,
  IonContent,
  IonButton,
  modalController,
  IonFooter,
  IonToolbar,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { logEvent, eventKeys } from "@/services/analitics";
import typesModule from "@/data/modules/typesModule";

export default {
  name: "Wallet",
  components: {
    HeaderWallet,
    CreditCardCard,
    IonPage,
    IonContent,
    IonButton,
    IonFooter,
    IonToolbar,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    logEvent(eventKeys.OPEN_WALLET);

    const cards = store.state.card;
    const user = store.state.user;
    const addCard = () => {
      router.push("/wallet/add-card");
    };

    return {
      addCard,
      user,
      cards,
    };
  },

  methods: {
    async openModalAddCard() {
      const modal = await modalController.create({
        component: AddCardModal,
        cssClass: "my-custom-class",
      });
      return modal.present();
    },
    removeCard(index) {
      this.$store.dispatch(typesModule.actions.DELETE_CARD, index);
    },
  },
};
