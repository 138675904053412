import axios from "@/utils/axios";

export default async function (
  orderId: number,
  { comment, product, packageProduct, deliveryTime, costBenefit }
) {
  const response = await axios.post(`/order/${orderId}`, {
    comment,
    product,
    package: packageProduct,
    deliveryTime,
    costBenefit,
  });

  const establishmentRate = response.data;

  return establishmentRate;
}
