
import { cartOutline } from "ionicons/icons";
import {
  IonPage,
  IonContent,
  IonList,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import OrderComponent from "@/components/order/OrderComponent.vue";
import OrderListSkeleton from "@/components/skeletonComponents/OrderListSkeleton.vue";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import ordersUser from "@/data/providers/orders/orders";
import { useRouter } from "vue-router";

export default {
  name: "OrdersList",
  components: {
    HeaderWithBalance,
    IonIcon,
    IonContent,
    IonPage,
    IonList,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    OrderComponent,
    IonRefresher,
    IonRefresherContent,
    OrderListSkeleton,
  },
  props: {
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const finishedOrdersRef: any = ref([]);
    const ordersRef: any = ref([]);
    const currentOrdersRef: any = ref([]);
    const loadingRef = ref(true);
    const cursorRef = ref(2);

    const router = useRouter();

    const getOrders = async () => {
      loadingRef.value = true;
      const ordersData = await ordersUser();

      ordersRef.value = ordersData;
      cursorRef.value = 2;

      loadingRef.value = false;
    };

    const getNextOrders = async (event: any) => {
      const newOrders = await ordersUser(cursorRef.value);

      newOrders.forEach((status) => {
        const index = ordersRef.value.findIndex(
          (currentStatus) => currentStatus.id == status.id
        );
        if (index != -1) {
          ordersRef.value[index].orders.push(...status.orders);
        }
      });

      cursorRef.value = cursorRef.value + 1;

      event.target.complete();
    };

    const goOrderDetails = async (orderId) => {
      router.push(`/order-details/${orderId}`);
    };

    const doRefresh = async (event) => {
      await getOrders();
      event.target.complete();
    };

    onMounted(getOrders);

    return {
      finishedOrdersRef,
      currentOrdersRef,
      ordersRef,
      cartOutline,
      loadingRef,
      cursorRef,
      getNextOrders,
      goOrderDetails,
      getOrders,
      doRefresh,
    };
  },
  watch: {
    refresh() {
      this.getOrders();
    },
  },

  methods: {
    dateToString(stringDate: string) {
      const date = new Date(stringDate);
      return date.toLocaleDateString();
    },
    hourToString(stringDate: string) {
      const date = new Date(stringDate);
      const options: any = {
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleString("pt-br", options);
    },
  },
};
