const othersModule = {
  state: {
    renderingTabs: undefined,
    appOptions: undefined,
  },
  mutations: {
    setRenderingTabs(state: any, value: any) {
      state.renderingTabs = value;
    },
    updateAppOptions(state: any, value: any) {
      state.appOptions = value;
    },
  },
  actions: {
    setRenderingTabs({ commit }: any, tab: any) {
      commit("setRenderingTabs", tab);
    },
    updateAppOptions({ commit }: any, appOptions) {
      commit("updateAppOptions", appOptions);
    },
  },
};

export default othersModule;
