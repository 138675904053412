
import {
  IonPage,
  IonContent,
  IonCheckbox,
  IonButton,
  alertController,
  IonCard,
  IonCardContent,
  IonFooter,
  IonToolbar,
} from "@ionic/vue";
import { pencilOutline } from "ionicons/icons";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import SessionsSkeleton from "@/components/skeletonComponents/SessionsSkeleton.vue";

import getSessionsData from "@/data/providers/user/user-sessions";
import removeSessionsData from "@/data/providers/user/user-sessions-remove";

export default {
  name: "ActiveSessions",
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonCheckbox,
    IonButton,
    IonCard,
    IonCardContent,
    IonFooter,
    IonToolbar,
    SessionsSkeleton,
  },
  setup() {
    const loadingRef = ref(true);

    const currentSessionsRef = ref({});
    const activeSessionsRef = ref([]);

    const getSessions = async () => {
      loadingRef.value = true;

      const sessions = await getSessionsData();

      activeSessionsRef.value = sessions.sessions;

      currentSessionsRef.value = sessions.currentSession;

      loadingRef.value = false;
    };

    onMounted(getSessions);

    return {
      loadingRef,
      currentSessionsRef,
      activeSessionsRef,
      pencilOutline,
    };
  },
  data: () => ({
    sessionsToLogout: [],
  }),
  methods: {
    dateToString(stringDate: string) {
      const date = new Date(stringDate);
      const options: any = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return date.toLocaleDateString("pt-br", options);
    },
    addSession(session, event) {
      if (!event.target.checked) {
        this.sessionsToLogout.push(session);
      } else {
        const index = this.removeSessionFromArray(session);
        this.sessionsToLogout.splice(index, 1);
      }
    },

    removeSessionFromArray(session) {
      return this.sessionsToLogout.findIndex((category) => {
        return category === session;
      });
    },

    getDevice(userAgent) {
      return userAgent.substring(
        userAgent.indexOf("(") + 1,
        userAgent.indexOf(")")
      );
    },

    async logoutAllSessions() {
      const logout = async () => {
        await this.activeSessionsRef.forEach((session) => {
          this.sessionsToLogout.push(session.id);
        });
        this.logoutSessions();
      };

      const alert = await alertController.create({
        header: "Encerrar todas as sessões?",
        buttons: [
          { text: "Não" },
          {
            text: "Sim",
            handler: () => {
              logout();
            },
          },
        ],
      });
      await alert.present();
    },

    logoutSessions() {
      if (this.sessionsToLogout.length != 0) {
        this.activeSessionsRef = this.activeSessionsRef.filter((objeto) => {
          return this.sessionsToLogout.indexOf(objeto.id) == -1;
        });
      }
      removeSessionsData(this.sessionsToLogout);
      this.sessionsToLogout = [];
    },
  },
};
