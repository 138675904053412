
import {
  IonPage,
  IonContent,
  IonItem,
  IonSpinner,
  IonList,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import { arrowBack, star } from "ionicons/icons";
import RatingStars from "@/components/RatingStars.vue";
import ItemRatings from "@/components/ItemRatings.vue";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { onMounted } from "vue";
import { Ref, ref } from "@vue/reactivity";
import { useRouter } from "vue-router";

import getOnlineInitialRatings from "@/data/providers/initial-ratings";
import getOnlineRatings from "@/data/providers/ratings";

export default {
  name: "Ratings",
  components: {
    IonPage,
    RatingStars,
    ItemRatings,
    IonContent,
    IonItem,
    IonSpinner,
    IonList,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonCard,
    HeaderWithBalance,
    IonCardContent,
  },
  props: {
    establishmentId: {
      type: String,
      default: null,
    },
  },
  setup(props: any) {
    const router = useRouter();
    const loadingRef = ref(true);

    const ratingsRef: Ref<any> = ref([]);
    const ratingRef = ref(0);
    const logo = ref("");
    let cursor = 1;

    const getInitialRatings = async () => {
      loadingRef.value = true;

      const initialRatingsResponse = await getOnlineInitialRatings(
        props.establishmentId
      );

      ratingRef.value = initialRatingsResponse.rating;

      ratingsRef.value = initialRatingsResponse.ratings;

      logo.value = initialRatingsResponse.logo;

      cursor = 1;
      loadingRef.value = false;
    };

    const getRatings = async (event: any) => {
      const newRatings = await getOnlineRatings(
        props.establishmentId,
        ++cursor
      );
      ratingsRef.value.push(...newRatings);
      event.target.complete();
    };

    onMounted(getInitialRatings);

    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    return {
      loadingRef,
      ratingsRef,
      ratingRef,
      getRatings,
      arrowBack,
      star,
      goBack,
      logo,
    };
  },
  methods: {
    formatRating(rating) {
      const format = rating.toFixed(1).replace(".", ".");
      return format;
    },
    dateToString(stringDate: string) {
      const date = new Date(stringDate);
      return date.toLocaleDateString();
    },
  },
};
