
import HeaderWallet from "@/components/headers/HeaderWallet.vue";
import TransactionCashBackCard from "@/components/TransactionCashbackCard.vue";
import TransactionsListSkeleton from "@/components/skeletonComponents/TransactionsListSkeleton.vue";
import { IonPage, IonContent, IonList, IonItem } from "@ionic/vue";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import transactions from "@/data/providers/transactions";
import { useStore } from "vuex";

import { logEvent, eventKeys } from "@/services/analitics";

export default {
  name: "Transaction",
  components: {
    HeaderWallet,
    TransactionCashBackCard,
    IonPage,
    IonContent,
    IonList,
    IonItem,
    TransactionsListSkeleton,
  },

  setup() {
    const transactionsDataRef = ref([]);
    const segmentTransactionsRef = ref([]);
    const loadingRef = ref(true);
    const store = useStore();

    const cashbackValue = store.state.user.cashbackValue;

    const getTransactions = async () => {
      loadingRef.value = true;
      const transactionsUserData = await transactions();
      transactionsDataRef.value = transactionsUserData;
      segmentTransactionsRef.value = transactionsUserData;
      loadingRef.value = false;
    };

    logEvent(eventKeys.OPEN_WALLET_HISTORY);

    onMounted(getTransactions);

    return {
      transactionsDataRef,
      loadingRef,
      segmentTransactionsRef,
      cashbackValue,
    };
  },
  data: function () {
    return {
      filterSegments: [
        { name: "Todas", value: null },
        { name: "Receitas", value: "credit" },
        { name: "Gastos", value: "debit" },
        { name: "Pendentes", value: "pending" },
      ],
      currentSegment: null,
    };
  },
  methods: {
    filterTransactionsBySegment(value) {
      this.currentSegment = value;
      if (this.currentSegment === null) {
        this.segmentTransactionsRef = this.transactionsDataRef;
      } else if (value == "pending") {
        this.segmentTransactionsRef = this.transactionsDataRef.filter(
          (transaction) => transaction.status == value
        );
      } else {
        this.segmentTransactionsRef = this.transactionsDataRef.filter(
          (transaction) => transaction.type == value
        );
      }
    },
  },
};
