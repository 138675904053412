
import { IonIcon, modalController } from "@ionic/vue";
import { caretDown, location } from "ionicons/icons";
import NeighborhoodsModal from "../../views/Neighborhoods.vue";
import CitiesVue from "@/views/Cities.vue";

export default {
  name: "HeaderEstablishmentsList",
  components: { IonIcon },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      caretDown,
      location,
    };
  },
  computed: {
    neighborhood() {
      return this.$store.state.neighborhood;
    },
    cashbackValue() {
      return this.$props.user?.cashbackValue ||
        this.$props.user?.cashbackValue === 0
        ? `R$ ${this.$props.user.cashbackValue.toFixed(2).replace(".", ",")}`
        : "";
    },
  },
  methods: {
    formatName(name) {
      const format = name.split(" ");
      return format[0];
    },
    openWallet() {
      this.$router.push("/wallet");
    },
    moneyMask(value) {
      return value ? `R$ ${value.toFixed(2).replace(".", ",")}` : "R$ 0,00";
    },
    async goToNeighborhood() {
      if (!this.$store.state.neighborhood.cityId) {
        const modal = await modalController.create({
          component: CitiesVue,
          cssClass: "my-custom-class",
        });

        return modal.present();
      }

      const modal = await modalController.create({
        component: NeighborhoodsModal,
        componentProps: { cityId: this.$store.state.neighborhood.cityId },
        cssClass: "my-custom-class",
      });

      return modal.present();
    },
  },
};
