const actions = {
  UPDATE_APP_OPTIONS: "updateAppOptions",
  CHANGE_NEIGHBORHOOD: "changeNeighborhood",
  SET_USER: "setLoginInfo",
  SET_USER_NOTIFICATION_TOKEN: "setUserNotificationToken",
  UPDATE_USER: "updateUserInfo",
  SET_USER_NAME: "setUserName",
  SET_PHONE_VERIFIED: "setUserPhoneVerified",
  LOGOFF: "logoff",
  SET_CARD: "setCardInfo",
  DELETE_CARD: "deleteCard",

  RENDERING_TABS: "setRenderingTabs",

  SET_FILTERS: "setFilters",
  DELETE_FILTERS: "deleteFilters",

  SET_SORT_BY: "setSortBy",
  STORES_SORT_BY: "storesSortBy",
  ITEMS_SORT_BY: "itemsSortBy",

  SET_CATEGORY: "setCategory",

  SET_SEARCH_NAME: "setSearchName",

  SET_UPDATE_PARAMS: "setSearchParams",

  DELETE_CLEAR_PARAMS: "cleanSearchParams",

  DELETE_CLEAR_PAYMENTS: "deleteParamsPayments",

  DELETE_CLEAR_FILTERS: "deleteParamsFilters",

  DELETE_CLEAR_CATEGORIES: "deleteParamsCategories",
  INIT_CART: "initCart",
  UPDATE_CART_ESTABLISHMENT_INFO: "updateInfo",
  RESET_CART: "resetCart",
  SELECTED_PAYMENT_METHOD: "selectedPaymentMethod",
  SELECTED_ADDRESS: "selectedAddress",

  ADD_QUANTITY_PRODUCT: "addQuantityProduct",
  EDIT_PRODUCT: "editProduct",
  REMOVE_QUANTITY_PRODUCT: "removeQuantityProduct",
  ADD_PRODUCT: "addProduct",
  REMOVE_PRODUCT: "removeProduct",
  UPDATE_NOTE_PRODUCT: "updateNoteProduct",

  ADD_ADDRESS: "addAddress",

  SET_DELIVERY_FEE: "setDeliveryFee",

  SET_COUPON: "setCoupon",
  SET_COUPON_NAME: "setCouponName",
  REVALIDATE_COUPON: "revalidateCoupon",

  INIT_PAYMENT: "initPayment",
  ADD_PAYMENT: "addPayment",

  UPDATE_CASHBACK: "updateCashback",
};

export default { actions };
