
import {
  IonPage,
  IonContent,
  IonButton,
  IonFooter,
  modalController,
  alertController,
  IonToolbar,
  toastController,
  IonIcon,
  isPlatform,
  useBackButton,
} from "@ionic/vue";
import { copySharp } from "ionicons/icons";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { defineComponent } from "@vue/runtime-core";
import typesModule from "@/data/modules/typesModule";
import ChangePhoneNumber from "@/data/providers/user/changePhoneNumber";
import QRCode from "qrcode";
import { Clipboard } from "@capacitor/clipboard";
import { useStore } from "vuex";
import { RateApp } from "capacitor-rate-app";

export default defineComponent({
  name: "ConfirmedOrder",
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonButton,
    IonFooter,
    IonToolbar,
    IonIcon,
  },
  props: {
    orderId: { type: Number, required: true },
    cashback: { type: String, default: "" },
    deliveryTime: {
      type: String,
      default: "",
    },
    pix: { type: [String, Boolean], default: undefined },
  },
  setup() {
    const store = useStore();
    store.dispatch(typesModule.actions.RESET_CART);

    if (isPlatform("capacitor")) {
      RateApp.requestReview();
    }

    useBackButton(10, () => {
      modalController.dismiss();
    });

    return {
      copySharp,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    qrcode() {
      let generateQR = "";
      QRCode.toDataURL(
        this.$props.pix,
        { errorCorrectionLevel: "H" },
        function (_err, url) {
          generateQR = url;
        }
      );

      return generateQR;
    },
  },
  methods: {
    accompanyOrder() {
      this.$router.push(`/order-details/${this.$props.orderId}`);
      modalController.dismiss();
    },
    async presentAlertChangePhoneNumber() {
      const alert = await alertController.create({
        header: "Alterar número",
        cssClass: "alert-input-phone",
        message:
          "O número do telefone é importante para contactá-lo.<br>Digite apenas números.",
        inputs: [
          {
            name: "phone",
            id: "phone",
            type: "tel",
            placeholder: "DDD+Telefone",
            attributes: {
              maxlength: 11,
              autocomplete: "tel-national",
            },
          },
        ],
        buttons: [
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              alert.dismiss();
            },
          },
          {
            text: "Enviar",
            handler: async (value) => {
              const response = await ChangePhoneNumber({
                phone: value.phone,
              });
              if (response) {
                this.$store.dispatch(typesModule.actions.UPDATE_USER, response);
              }
            },
          },
        ],
      });
      return alert.present();
    },
    async clipboardPix() {
      await Clipboard.write({
        string: this.$props.pix,
      });

      const toast = await toastController.create({
        message: "Pix Copia e Cola copiado.",
        duration: 2000,
      });
      toast.present();
    },
  },
});
