
import {
  IonHeader,
  IonContent,
  IonSkeletonText,
  IonPage,
  IonList,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonButton,
  IonFooter,
  IonToolbar,
  modalController,
  toastController,
} from "@ionic/vue";
import SimpleHeader from "@/components/headers/SimpleHeader.vue";
import NeighborhoodsModal from "./Neighborhoods.vue";
import { homeSharp, square, triangle } from "ionicons/icons";
import getOnlineCities from "@/data/providers/cities";
import { onMounted, ref, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Cities",
  components: {
    IonPage,
    SimpleHeader,
    IonHeader,
    IonSkeletonText,
    IonContent,
    IonList,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonSearchbar,
    IonButton,
    IonFooter,
    IonToolbar,
  },
  setup() {
    const citiesRef: any = ref([]);
    const loadingRef = ref(true);
    const store = useStore();
    let citiesResponse: any[];

    const getCities = async () => {
      loadingRef.value = true;
      citiesResponse = await getOnlineCities();

      citiesRef.value = citiesResponse;

      loadingRef.value = false;
    };

    const filterList = async (evt) => {
      citiesRef.value = citiesResponse;
      const searchTerm = evt.srcElement.value;

      if (!searchTerm) {
        return;
      }

      citiesRef.value = citiesResponse.filter((currentCity) => {
        if (currentCity.name && searchTerm) {
          return (
            currentCity.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >
            -1
          );
        }
      });
    };

    onMounted(getCities);

    return {
      loadingRef,
      citiesRef,
      homeSharp,
      square,
      triangle,
      filterList,
      store,
    };
  },
  data() {
    return {
      citySelected: undefined,
    };
  },
  methods: {
    markCity(city) {
      this.citySelected = city;
    },
    async selectCity() {
      if (!this.citySelected) {
        const toast = await toastController.create({
          message: "Por favor, selecione uma cidade antes de prosseguir",
          duration: 2000,
        });
        toast.present();
        return;
      }
      modalController.dismiss();

      const modal = await modalController.create({
        component: NeighborhoodsModal,
        componentProps: {
          cityId: this.citySelected.id,
          citySlug: this.citySelected.slug,
          cityName: this.citySelected.name,
        },
        cssClass: "my-custom-class",
      });
      modal.present();
    },
    hasCityAndNeigh() {
      if (
        this.store.state.neighborhood.cityId &&
        this.store.state.neighborhood.id
      ) {
        return true;
      }

      return false;
    },
  },
});
