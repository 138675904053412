
import {
  IonPage,
  IonContent,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

import EstablishmentCard from "@/components/cards/EstablishmentCard.vue";
import EstablishmentCardSkeleton from "@/components/skeletonComponents/EstablishmentCardSkeleton.vue";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import EstablishmentsBannerList from "@/data/providers/establishments-banner-list";

import { Ref, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { watch, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Inicio",
  components: {
    EstablishmentCard,
    EstablishmentCardSkeleton,
    IonContent,
    IonPage,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonGrid,
    IonRow,
    IonCol,
    HeaderWithBalance,
  },
  props: ["IdBanner", "banner"],
  setup(props: any) {
    const router = useRouter();
    const store = useStore();

    const establishmentsRef: Ref = ref([]);
    const loadingRef = ref(true);
    const cursorRef = ref(1);

    const neighborhoodState = store.state.neighborhood;

    const user = store.state.user;

    const getNextEstablishments = async (event) => {
      const newEstablishments = await EstablishmentsBannerList(
        neighborhoodState.id,
        props.IdBanner,
        cursorRef.value
      );

      establishmentsRef.value.push(...newEstablishments);

      cursorRef.value += 1;
      event.target.complete();
    };

    const getEstablishments = async () => {
      loadingRef.value = true;
      const listEstablishments = await EstablishmentsBannerList(
        neighborhoodState.id,
        props.IdBanner
      );

      establishmentsRef.value = listEstablishments;

      cursorRef.value = 2;
      loadingRef.value = false;
    };

    const goToEstablishment = (id) => {
      router.push(`/estabelecimento/${id}`);
    };

    const doRefresh = async (event) => {
      await getEstablishments();
      event.target.complete();
    };

    onMounted(getEstablishments);

    watch(neighborhoodState, () => getEstablishments());

    return {
      loadingRef,
      establishmentsRef,
      cursorRef,
      user,
      doRefresh,
      neighborhoodState,
      goToEstablishment,
      getNextEstablishments,
    };
  },
  methods: {},
});
