
import {
  IonPage,
  IonContent,
  IonSkeletonText,
  IonButton,
  IonFooter,
  IonToolbar,
  modalController,
  IonCard,
  IonCardContent,
  toastController,
  IonIcon,
  alertController,
} from "@ionic/vue";
import { informationCircleOutline } from "ionicons/icons";
import { ref } from "@vue/reactivity";

import { defineComponent } from "vue";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { useStore } from "vuex";

import establishmentAddresses from "@/data/providers/establishments/establishment-addresses";
import typesModule from "@/data/modules/typesModule";
import AddAddress from "../Profile/Address/ManageAddress.vue";
export default defineComponent({
  name: "DeliveryAddress",
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonFooter,
    IonToolbar,
    IonButton,
    IonSkeletonText,
    IonCard,
    IonCardContent,
    IonIcon,
  },

  setup() {
    const store = useStore();
    const addressNeighborhoodsRef = ref([]);
    const loadingRef = ref(true);
    const withDraw = store.state.cart.isWithdraw;

    return {
      store,
      withDraw,
      addressNeighborhoodsRef,
      modalController,
      loadingRef,
      informationCircleOutline,
    };
  },
  async mounted() {
    await this.getAddresses();
  },
  methods: {
    async getAddresses() {
      this.loadingRef = true;
      const establishmentId = this.$store.state.cart.establishmentId;
      const addressesData = await establishmentAddresses(establishmentId);

      this.addressNeighborhoodsRef = addressesData;

      this.loadingRef = false;
    },
    async addAddress() {
      const modal = await modalController.create({
        component: AddAddress,
      });

      modal.onDidDismiss().then((res) => {
        if (res?.data?.success) {
          this.getAddresses();
        }
      });

      modal.present();
    },
    async chooseAddress(
      address,
      neighborhoodName = null,
      cityId = null,
      deliveryFee = null
    ) {
      if (address == "withDraw") {
        const alert = await alertController.create({
          header: "Retirada na loja",
          message: "Você escolheu retirar o pedido na loja. Tem certeza?",
          buttons: [
            { text: "Não", handler: () => alert.dismiss() },
            {
              text: "Sim",
              handler: () => {
                this.modalController.dismiss({
                  isWithdraw: true,
                  confirm: true,
                });

                this.$store.dispatch(typesModule.actions.SET_DELIVERY_FEE, 0);
                this.$store.dispatch(
                  typesModule.actions.SELECTED_ADDRESS,
                  undefined
                );
              },
            },
          ],
        });

        alert.present();
      } else {
        this.$store.dispatch(typesModule.actions.SELECTED_ADDRESS, {
          addressId: address.id,
          streetName: address.street_name,
          addressNumber: address.address_number,
          complement: address.complement,
          reference: address.reference,
          neighborhood: address.neighborhood_id,
        });

        this.$store.dispatch(typesModule.actions.CHANGE_NEIGHBORHOOD, {
          id: address.neighborhood_id,
          name: neighborhoodName,
          cityId: cityId,
        });

        this.$store.dispatch(typesModule.actions.SET_DELIVERY_FEE, deliveryFee);

        this.modalController.dismiss({
          isWithdraw: false,
          confirm: true,
        });
      }
    },
    async openToast(message) {
      const toast = await toastController.create({
        message: message,
        duration: 3000,
      });
      return toast.present();
    },
    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
  },
});
