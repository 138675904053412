<template>
  <orders-list v-if="isAuthenticatedRef" :refresh="refresh" />
  <login v-else />
</template>

<script>
import Login from "../Login/Login.vue";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { watch } from "@vue/runtime-core";
import OrdersList from "../Order/OrdersList.vue";
import { onIonViewDidEnter } from "@ionic/vue";

export default {
  name: "OrdersPage",
  components: { Login, OrdersList },
  setup: () => {
    const store = useStore();

    const isAuthenticatedRef = ref(false);
    const refresh = ref(false);

    isAuthenticatedRef.value = !!store.state.user.token;

    watch(store.state.user, () => {
      isAuthenticatedRef.value = !!store.state.user.token;
    });

    onIonViewDidEnter(() => {
      document.title = "Pedidos em TheLivery";

      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Entregamos tudo o que você precisa com dinheiro de volta (cashback). São milhares de opções de delivery: do pãozinho ao sushi e do sabonete a remédios."
        );
      refresh.value = !refresh.value;
    });

    return {
      isAuthenticatedRef,
      refresh,
    };
  },
};
</script>
