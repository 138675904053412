import axios from "@/utils/axios";

export default async function (neighborhood: any, page?: number) {
  const response = await axios.get(
    `/neighborhood/${neighborhood}/establishments${page ? "?page=" + page : ""}`
  );

  const establishments = response.data;

  return establishments;
}
