
import { IonIcon, IonButton, modalController } from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { useRouter } from "vue-router";

export default {
  name: "HeaderWithBalance",
  components: { IonButton, IonIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
    hasGoBack: {
      type: Boolean,
      default: true,
    },
    hasGoBackModal: {
      type: Boolean,
      default: false,
    },
    balance: {
      type: Number,
      default: null,
    },
    isBack: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    const goBackModal = () => {
      modalController.dismiss();
    };

    return {
      arrowBack,
      goBack,
      goBackModal,
    };
  },

  methods: {
    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
  },
};
