import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Tabs from "../views/Tabs/Tabs.vue";
import Cities from "../views/Cities.vue";
import Neighborhoods from "../views/Neighborhoods.vue";
import Establishment from "../views/Establishments/Establishment.vue";
import EstablishmentInfo from "../views/Establishments/EstablishmentInfo.vue";
import Ratings from "../views/Establishments/Ratings.vue";
import LoginWithPassword from "../views/Login/LoginWithPassword.vue";
import LoginWithPhone from "../views/Login/LoginWithPhone.vue";
import ConfirmLogin from "../views/Login/ConfirmLogin.vue";
import Register from "../views/Register.vue";
import Products from "../views/Establishments/Products.vue";
import ProfileInfo from "../views/Profile/ProfileInfo.vue";
import Faq from "../views/Profile/Faq/Faq.vue";
import ActiveSessions from "../views/Profile/ActiveSessions/ActiveSessions.vue";
import Wallet from "../views/Profile/Wallet/Wallet.vue";
import AddCard from "../views/Profile/Wallet/AddCard.vue";
import Transactions from "../views/Profile/Wallet/Transactions.vue";
import Address from "../views/Profile/Address/Address.vue";
import Coupons from "@/views/Profile/Coupons/Coupons.vue";
import Raffles from "@/views/Profile/Raffles/Raffles.vue";
import RafflesDetails from "@/views/Profile/Raffles/Details.vue";
import ManageAddress from "../views/Profile/Address/ManageAddress.vue";
import OrderDetails from "../views/Order/OrderDetails.vue";
import Filters from "../views/Search/SearchFilters.vue";
import SearchList from "../views/Search/SearchList.vue";
import SimpleSearch from "../views/Search/SimpleSearch.vue";
import Cart from "../views/Cart/Cart.vue";
import EditProfile from "../views/Profile/EditProfile.vue";
import ValidatePhone from "../views/Login/ValidatePhone.vue";
import EstablishmentsBannerPage from "../views/EstablishmentsBannerPage.vue";
import CouponsPage from "../views/Explore/CouponsPage.vue";
import PromotionsPage from "../views/Explore/PromotionsPage.vue";
import StoresPage from "../views/Explore/Shop/StoresPage.vue";
import Home from "../views/Home.vue";

import { isPlatform } from "@ionic/vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/tabs/inicio",
  },
  {
    path: "/tabs/",
    component: Tabs,
    children: [
      {
        path: "",
        redirect: "/tabs/inicio",
      },
      {
        path: "inicio",
        component: () => import("@/views/Tabs/InitialPage.vue"),
      },
      {
        path: "Orders",
        component: () => import("@/views/Tabs/Orders.vue"),
      },
      {
        path: "perfil",
        component: () => import("@/views/Profile/Profile.vue"),
      },
      {
        path: "search",
        component: () => import("@/views/Tabs/Search.vue"),
      },
      {
        path: "explore",
        component: () => import("@/views/Tabs/Explore.vue"),
      },
    ],
  },
  {
    path: "/busca",
    name: "search-list",
    component: SearchList,
  },
  {
    path: "/lista-busca",
    name: "simple-search",
    component: SimpleSearch,
  },
  {
    path: "/filters",
    component: Filters,
  },
  {
    path: "/order-details/:orderId",
    component: OrderDetails,
    props: true,
  },
  {
    path: "/login/loginWithPassword",
    component: LoginWithPassword,
  },
  {
    path: "/login/loginWithPhone",
    component: LoginWithPhone,
  },
  {
    path: "/cadastrar",
    component: Register,
  },
  {
    path: "/login/confirm-login",
    name: "confirm-login",
    props: true,
    component: ConfirmLogin,
  },
  {
    path: "/validate-phone",
    name: "validate-phone",
    props: true,
    component: ValidatePhone,
  },
  {
    path: "/profile-info",
    component: ProfileInfo,
  },
  {
    path: "/faq",
    component: Faq,
  },
  { path: "/edit-profile", component: EditProfile },
  {
    path: "/active-sessions",
    component: ActiveSessions,
  },
  {
    path: "/address",
    component: Address,
    props: true,
  },
  {
    path: "/cupons",
    component: Coupons,
    props: true,
  },
  {
    path: "/sorteios",
    component: Raffles,
  },
  {
    path: "/sorteios/:raffleSlug",
    component: RafflesDetails,
  },
  {
    path: "/sorteios/:raffleSlug/refer/:reference",
    component: RafflesDetails,
  },
  {
    path: "/address/add-address",
    component: ManageAddress,
    props: true,
  },
  {
    path: "/wallet",
    component: Wallet,
    props: true,
  },
  {
    path: "/wallet/transactions",
    component: Transactions,
  },
  {
    path: "/wallet/add-card",
    component: AddCard,
  },
  {
    path: "/cidades",
    component: Cities,
  },
  {
    path: "/cidades/:cityId/bairros",
    component: Neighborhoods,
    props: true,
  },
  {
    path: "/estabelecimento/:establishmentId",
    component: Establishment,
    props: true,
  },
  {
    path: "/loja/:citySlug/:categorySlug/:establishmentSlug",
    component: Establishment,
    props: true,
  },
  {
    path: "/estabelecimento/:establishmentId/info",
    component: EstablishmentInfo,
    props: true,
  },
  {
    path: "/estabelecimento/:establishmentId/avaliacoes",
    component: Ratings,
    props: true,
  },
  {
    path: "/produto/:productId",
    component: Products,
    props: true,
  },
  {
    path: "/cart",
    component: Cart,
  },
  {
    path: "/estabelecimentos-banner/:IdBanner",
    name: "estabelecimentos-banner",
    component: EstablishmentsBannerPage,
    props: true,
  },
  {
    path: "/explore/coupons",
    component: CouponsPage,
  },
  {
    path: "/explore/promotions",
    component: PromotionsPage,
  },
  {
    path: "/explore/stores",
    component: StoresPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
