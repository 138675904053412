
import { IonCard, IonImg, IonBadge } from "@ionic/vue";

export default {
  name: "TransactionCashbackCard",
  components: { IonCard, IonImg, IonBadge },
  props: ["transaction"],

  data() {
    return {
      debit: "debit",
      credit: "credit",
    };
  },
  methods: {
    dateToString(stringDate: string) {
      const date = new Date(stringDate);
      return date.toLocaleDateString();
    },

    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
  },
};
