
import {
  IonPage,
  IonIcon,
  IonButton,
  IonContent,
  IonImg,
  IonInput,
  IonFooter,
  IonToolbar,
  modalController,
  toastController,
  IonCard,
  IonCardContent,
  IonSkeletonText,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { arrowBack, remove, add } from "ionicons/icons";
import { Ref, ref } from "@vue/reactivity";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";

import SelectUniqueSuboption from "@/components/SelectUniqueSuboption.vue";
import SelectMultipleSuboption from "@/components/SelectMultipleSuboption.vue";
import SelectQuantitySuboption from "@/components/SelectQuantitySuboption.vue";
import getOnlineProductData from "@/data/providers/product";
import typesModule from "@/data/modules/typesModule";
import OptionsCardSkeleton from "@/components/skeletonComponents/OptionsCardSkeleton.vue";

export default defineComponent({
  name: "Products",
  components: {
    HeaderWithBalance,
    SelectMultipleSuboption,
    SelectUniqueSuboption,
    SelectQuantitySuboption,
    IonPage,
    IonIcon,
    IonButton,
    IonContent,
    IonImg,
    IonInput,
    IonFooter,
    IonToolbar,
    IonCard,
    IonCardContent,
    OptionsCardSkeleton,
    IonSkeletonText,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
    establishmentPromotionId: {
      type: String,
      default: null,
    },
    editIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const loadingRef = ref(true);
    const productRef: Ref<any> = ref({});

    return {
      arrowBack,
      loadingRef,
      productRef,
      add,
      remove,
      modalController,
    };
  },
  data: () => {
    return {
      totalPrice: 0,
      productPrice: 0,
      selected: [],
      quantity: 1,
      productNote: "",
    };
  },
  mounted() {
    this.getProductData();
  },
  methods: {
    goBack() {
      if (history?.state?.back) {
        this.$router.back();
      } else {
        this.$router.push("/tabs/inicio");
      }
    },

    async getProductData() {
      this.loadingRef = true;
      const productData = await getOnlineProductData(this.productId);

      this.productRef = productData;

      if (this.editIndex || this.editIndex == 0) {
        const product = this.$store.state.cart?.products?.[this.editIndex];
        if (product.id == productData.id) {
          this.productNote = product.note;
          this.quantity = product.quantity;

          product.options.forEach((selectedOption) => {
            const option = productData.options.find(
              (currentOption) => selectedOption.id == currentOption.id
            );
            option.selected = selectedOption;
          });

          this.selected = product.options;
          this.updatePrice();
        }
      }

      this.loadingRef = false;
    },
    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
    changeSelection(option, suboptions) {
      const optionSelected = this.select(option, suboptions);

      if (
        optionSelected?.suboptions?.length &&
        optionSelected.suboptions.length == option.max_selected
      ) {
        this.scrollToNextFrom(option.id);
      }
    },
    select(option, suboptions) {
      const optionSelected = this.addToSelectedArray(option, suboptions);
      this.updatePrice();

      return optionSelected;
    },
    addToSelectedArray(option, suboptions) {
      const optionSelectedIndex = this.selected.findIndex(
        (selectedOption) => selectedOption.id == option.id
      );

      const existOptionSelected = optionSelectedIndex != -1;

      if (existOptionSelected) {
        const deselect = suboptions.length == 0 || suboptions[0] == undefined;
        if (deselect) {
          this.selected.splice(optionSelectedIndex, 1);
          return;
        }
        this.selected[optionSelectedIndex].suboptions = suboptions;
        return this.selected[optionSelectedIndex];
      }
      const createdOption = {
        id: option.id,
        name: option.name,
        plus: option.plus,
        maxSelected: option.max_selected,
        mandadory: option.mandatory,
        suboptions,
      };
      this.selected.push(createdOption);

      return createdOption;
    },
    updatePrice() {
      const productPrice = this.selected.reduce((acc, option) => {
        if (option.plus == 0) {
          if (option.maxSelected == 0) {
            return (
              acc +
              option.suboptions.reduce(
                (acc, suboption) => acc + suboption.price,
                0
              )
            );
          }
          const optionValue =
            this.productRef.type.establishment.split == "S"
              ? option.suboptions.reduce(
                  (acc, suboption) => acc + suboption.price,
                  0
                ) / option.suboptions.length
              : option.suboptions.reduce(
                  (max, suboption) =>
                    suboption.price > max ? suboption.price : max,
                  0
                );

          return acc + optionValue;
        } else {
          const optionValue = option.suboptions.reduce(
            (acc, suboption) => acc + suboption.price * suboption.quantity,
            0
          );

          return acc + optionValue;
        }
      }, 0);

      this.productPrice = productPrice;

      this.totalPrice = productPrice * this.quantity;
    },

    removeProduct() {
      if (this.quantity == 1) {
        return;
      } else {
        this.quantity--;
        this.updatePrice();
      }
    },
    addProduct() {
      this.quantity++;
      this.updatePrice();
    },

    productsMandatory() {
      const Mandatory = this.productRef.options.filter(
        (option) => option.mandatory === 1
      );
      return Mandatory;
    },

    async messageCartToast(productName: string) {
      const toast = await toastController.create({
        message: `Escolha uma opção de ${productName}`,
        duration: 2000,
      });

      return toast.present();
    },

    scrollToNextFrom(elementId) {
      const element = document.getElementById(`option${elementId}`);

      if (element && element.nextElementSibling) {
        const nextElementId = element.nextElementSibling.id;

        this.scrollTo(nextElementId);
      }
    },
    scrollTo(elementId) {
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },

    quantitySelected(optionId, maxSelected) {
      const optionSelected = this.selected.find(
        (selectedOption) => selectedOption.id == optionId
      );

      return `${optionSelected?.suboptions?.length || 0} de ${maxSelected}`;
    },

    addToCart() {
      if (this.productsMandatory) {
        if (this.selected.length > 0) {
          const select = this.productsMandatory().filter(
            (option) =>
              !this.selected.some((element) => element.id === option.id)
          );
          if (select.length > 0) {
            this.scrollTo(`option${select[0].id}`);
            this.messageCartToast(select[0].name);
            return;
          } else {
            if (this.editIndex || this.editIndex == 0) {
              this.$store.dispatch(typesModule.actions.EDIT_PRODUCT, {
                index: this.$props.editIndex,
                product: {
                  id: this.productRef.id,
                  name: this.productRef.name,
                  quantity: this.quantity,
                  options: this.selected,
                  totalPrice: this.totalPrice,
                  price: this.productPrice,
                  note: this.productNote,
                  simple: this.productRef.simple,
                },
              });

              modalController.dismiss();
              return;
            }

            this.$store.dispatch(typesModule.actions.ADD_PRODUCT, {
              id: this.productRef.id,
              name: this.productRef.name,
              quantity: this.quantity,
              options: this.selected,
              totalPrice: this.totalPrice,
              price: this.productPrice,
              note: this.productNote,
              simple: this.productRef.simple,
            });
          }
        } else {
          this.scrollTo(`option${this.productsMandatory()[0].id}`);
          this.messageCartToast(this.productsMandatory()[0].name);
          return;
        }
      }

      if (this.$props.establishmentPromotionId) {
        this.$router.push(
          `/estabelecimento/${this.$props.establishmentPromotionId}`
        );
      }

      this.modalController.dismiss();
    },
  },
});
