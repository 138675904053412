
import { defineComponent, Ref, ref } from "vue";
import {
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonItemGroup,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonLabel,
  IonChip,
} from "@ionic/vue";
import SortByGet from "@/data/providers/sort-by";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import typesModule from "@/data/modules/typesModule";
import searchPost from "@/data/providers/search/search-post";
import EstablishmentCard from "@/components/cards/EstablishmentCard.vue";
import EstablishmentCardSkeleton from "@/components/skeletonComponents/EstablishmentCardSkeleton.vue";
import NetworkError from "@/components/NetworkError.vue";

export default defineComponent({
  name: "ItemsSearchBy",
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonItemGroup,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonLabel,
    IonChip,
    EstablishmentCard,
    EstablishmentCardSkeleton,
    NetworkError,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const establishmentsRef: Ref = ref([]);
    const loadingRef = ref(true);
    const occurredErrorRef = ref(false);
    const cursorRef = ref(2);

    const sortBy = [...SortByGet];

    const search = store.state.search;

    let filtersValue;
    let idCategories;
    let payments;

    const getEstablishments = async () => {
      try {
        loadingRef.value = true;
        idCategories = store.state.search.categories.map(
          (category) => category.id
        );

        if (store.state.search.searchFilters) {
          filtersValue = store.state.search.searchFilters.map(
            (filters) => filters.value
          );
        }

        if (store.state.search.payments) {
          payments = store.state.search.payments.map((payments) => payments.id);
        }

        const establishmentsData = await searchPost({
          neighborhoodId: store.state.neighborhood.id,
          sortBy: store.state.search.itemsSortBy.value,
          searchValue: store.state.search.searchName,
          filters: filtersValue,
          payments: payments,
          categories: idCategories,
          queryBy: "tag",
        });

        establishmentsRef.value = establishmentsData;
        occurredErrorRef.value = false;
        cursorRef.value = 2;
      } catch (_error) {
        occurredErrorRef.value = true;
      } finally {
        loadingRef.value = false;
      }
    };

    const getNextEstablishments = async (event: any) => {
      try {
        idCategories = store.state.search.categories.map(
          (category) => category.id
        );

        if (store.state.search.searchFilters) {
          filtersValue = store.state.search.searchFilters.map(
            (filters) => filters.value
          );
        }

        if (store.state.search.payments) {
          payments = store.state.search.payments.map((payments) => payments.id);
        }
        const newEstablishments = await searchPost({
          neighborhoodId: store.state.neighborhood.id,
          sortBy: store.state.search.itemsSortBy.value,
          searchValue: store.state.search.searchName,
          filters: filtersValue,
          payments: payments,
          categories: idCategories,
          page: cursorRef.value,
          queryBy: "tag",
        });

        establishmentsRef.value.push(...newEstablishments);

        cursorRef.value = cursorRef.value + 1;
      } finally {
        event.target.complete();
      }
    };

    const goToEstablishment = (id) => {
      router.push(`/estabelecimento/${id}`);
    };

    const removeFilterPayment = (payment) => {
      store.dispatch(typesModule.actions.DELETE_CLEAR_PAYMENTS, payment);
      getEstablishments();
    };

    const removeFilterFilters = (filters) => {
      store.dispatch(typesModule.actions.DELETE_CLEAR_FILTERS, filters);
      getEstablishments();
    };

    const removeFilterCategories = (categories) => {
      store.dispatch(typesModule.actions.DELETE_CLEAR_CATEGORIES, categories);
      getEstablishments();
    };

    return {
      store,
      sortBy,
      search,
      loadingRef,
      establishmentsRef,
      cursorRef,
      goToEstablishment,
      getNextEstablishments,
      getEstablishments,
      removeFilterPayment,
      removeFilterFilters,
      removeFilterCategories,
      occurredErrorRef,
    };
  },
  data: function () {
    return {
      itemsSortBy: {},
    };
  },
  watch: {
    itemsSortBy: function () {
      this.store.dispatch(typesModule.actions.ITEMS_SORT_BY, this.itemsSortBy);
      this.getEstablishments();
    },
  },
  mounted() {
    this.getEstablishments();
  },
});
