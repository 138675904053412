
import {
  IonPage,
  IonContent,
  IonInput,
  IonIcon,
  IonToggle,
  IonButton,
  modalController,
  toastController,
  IonFooter,
  IonToolbar,
  loadingController,
  IonCard,
  IonCardContent,
  isPlatform,
  alertController,
} from "@ionic/vue";
import { ticketOutline } from "ionicons/icons";
import { App } from "@capacitor/app";

import sendValidationSMS from "@/data/providers/user/sendValidationSMS";
import createOrder from "@/data/providers/orders/order-create";
import ChangePhoneNumber from "@/data/providers/user/changePhoneNumber";
import PaymentMethods from "./PaymentMethods.vue";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import DataInvoiceModal from "@/components/modals/DataInvoiceModal.vue";
import DeliveryAddress from "./deliveryAddress.vue";
import typesModule from "@/data/modules/typesModule";
import ModalChangeProduct from "../ModalChangeProduct.vue";
import ConfirmedOrder from "../ConfirmedOrder.vue";
import ModalConfirmOrder from "@/components/modals/ModalConfirmOrder.vue";
import Login from "../Login/Login.vue";

import { logEvent, eventKeys } from "@/services/analitics";
import ValidatePhoneVue from "../Login/ValidatePhone.vue";
import CouponsModal from "./CouponsModal.vue";
import { mask } from "vue-the-mask";

export default {
  name: "Cart",
  directives: { mask },
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonInput,
    IonIcon,
    IonToggle,
    IonButton,
    IonFooter,
    IonToolbar,
    IonCard,
    IonCardContent,
  },
  setup() {
    return {
      ticketOutline,
    };
  },
  data: function () {
    return {
      isWithdraw: false,
      deliveryNote: "",
      taxpayerNumber: "",
      walletUsed: 0,
      paymentData: false,
    };
  },
  computed: {
    couponsMessage() {
      const cartState = this.$store.state.cart;
      if (cartState?.coupon?.name) {
        return this.$store.state.cart.coupon.name;
      }
      if (cartState?.couponsAvailable) {
        return cartState.couponsAvailable == 1
          ? "Você tem 1 cupom disponível"
          : `Você tem ${cartState.couponsAvailable} cupons disponíveis`;
      }
      return "Tem cupom? Adicione-o aqui.";
    },
    titleButton() {
      const payment = this.$store.state.cart.paymentMethod;
      const address = this.$store.state.cart.address;
      const token = this.$store.state.user.token;
      const phone = this.$store.state.user.phone;

      if (!token) {
        return "Fazer login";
      }

      if (!phone) {
        return "Informar Celular";
      }

      if (!payment?.pix && !payment?.id) {
        return "Selecionar Pagamento";
      }
      if (!address && !this.isWithdraw) {
        return "Selecionar Endereço";
      }

      return "Confirmar Pedido";
    },
    getTotal() {
      return this.productSelected.totalPrice - this.walletUsed;
    },
    productSelected() {
      return this.$store.state.cart;
    },
    cashbackGiven() {
      const cashbackEstablishment =
        this.$store.state.cart.isCashbackCampaign?.discount_percentage || 0;

      const price =
        this.$store.state.cart.price -
        (this.productSelected?.coupon?.discount || 0);

      const cashback = price * (cashbackEstablishment / 100);

      return this.valueRound(cashback);
    },

    moneyValue: function () {
      return this.$store.state.cart.paymentMethod?.change;
    },
    addressStreetNameComputed: function () {
      return this.$store.state.cart.address?.streetName;
    },

    addressNumberComputed: function () {
      return this.$store.state.cart.address?.addressNumber;
    },

    paymentMethodComputed: function () {
      if (this.$store.state.cart.paymentMethod?.paymentMethod?.name) {
        return {
          name: this.$store.state.cart.paymentMethod?.paymentMethod?.name,
          description: "Pagar na entrega",
        };
      }
      if (
        this.$store.state.cart.paymentMethod?.nickname &&
        this.$store.state.cart.isOnlinePayment
      ) {
        return {
          name: this.$store.state.cart.paymentMethod.nickname,
          description: "Pagamento online",
        };
      }

      return {
        name: "",
        description: "",
      };
    },
    balance() {
      return this.$store.state.user.cashbackValue;
    },
  },
  watch: {
    "productSelected.price": function () {
      if (this.$store.state.cart?.coupon?.name) {
        this.$store.dispatch(typesModule.actions.REVALIDATE_COUPON);
      }

      this.$data.walletUsed = 0;
    },
  },

  methods: {
    ionViewWillEnter() {
      if (
        this.$store.state.cart?.address?.neighborhood !=
        this.$store.state.neighborhood.id
      ) {
        this.$store.dispatch(typesModule.actions.SELECTED_ADDRESS, undefined);
      }
    },
    emptyCart() {
      if (history?.state?.back) {
        this.$router.back();
      } else {
        this.$router.push("/tabs/inicio");
      }
    },

    async onConfirm() {
      const payment = this.$store.state.cart.paymentMethod;
      const address = this.$store.state.cart.address;
      const token = this.$store.state.user.token;
      const phone = this.$store.state.user.phone;
      const minimumValue = this.$store.state.cart.minimumValue;

      if (!token) {
        return this.openLoginModal();
      }
      if (!phone) {
        return this.openAlertPhone();
      }
      if (!payment?.pix && !payment?.id) {
        return this.openPaymentMethodsModal();
      }
      if (!address && !this.isWithdraw) {
        return this.openDeliveryAddressModal();
      }

      if (minimumValue > this.productSelected.price) {
        return this.openToast(
          "O subtotal deve ser maior ou igual que o pedido mínimo (" +
            this.moneyMask(minimumValue) +
            ").",
          4000
        );
      }

      return this.openConfirmOrder();
    },

    async showLoading(message) {
      const loading = await loadingController.create({
        message: message,
      });

      loading.present();
    },

    async showModalConfirmedOrder({
      orderId,
      cashback,
      deliveryTime,
      pix = false,
    }) {
      const modal = await modalController.create({
        component: ConfirmedOrder,
        componentProps: { orderId, cashback, deliveryTime, pix },
      });

      await modal.present();
    },

    async createOrder() {
      const cart = this.$store.state.cart;

      let appVersion;
      if (isPlatform("capacitor")) {
        const appInfo = await App.getInfo();
        appVersion = appInfo.build;
      }

      const orderObject = {
        establishmentId: cart.establishmentId,
        productsPrice: cart.price,
        change: cart.paymentMethod.change,
        paymentMethodId: cart.paymentMethod.id,
        pix: cart.paymentMethod.pix,
        addressId: this.isWithdraw ? undefined : cart.address?.addressId,
        cardId: cart.paymentMethod.token,
        withdraw: this.$data.isWithdraw,
        cashbackCampaignId: cart.isCashbackCampaign?.id,
        walletUsed: this.$data.walletUsed,
        coupon: cart?.coupon?.name,
        note: this.$data.deliveryNote,
        taxpayerNumber: this.$data.taxpayerNumber,
        appVersion,
        platform: this.getPlatform(),
        products: cart.products.map((product) => ({
          id: product.id,
          note: product.note,
          quantity: product.quantity,
          options: product.options.map((option) => ({
            id: option.id,
            suboptions: option.suboptions.map((suboption) => ({
              id: suboption.id,
              quantity: suboption.quantity,
            })),
          })),
        })),
      };

      try {
        this.showLoading("Processando pedido...");

        const response = await createOrder(orderObject);
        if (response.success) {
          await this.$router.replace("/tabs/inicio");

          await this.showModalConfirmedOrder({
            orderId: response.data.id,
            cashback: this.cashbackGiven
              ? this.moneyMask(this.cashbackGiven)
              : "",
            deliveryTime: cart.deliveryTime,
            pix: response.data.qrcode,
          });

          if (this.$store.state.others.renderingTabs) {
            await this.$router.push(this.$store.state.others.renderingTabs);
            this.$router.push("/tabs/inicio");
          }

          this.$store.dispatch(
            typesModule.actions.UPDATE_CASHBACK,
            this.$store.state.user.cashbackValue - this.$data.walletUsed
          );

          logEvent(eventKeys.MAKE_PURCHASE, {
            CASHBACK_ATIVO: cart.isCashbackCampaign?.id,
            SALDO_DA_CARTEIRA: cart.walletUsed,
            TOTAL_COMPRADO: cart.totalPrice,
            BAIRRO_ID: this.$store.state.neighborhood.id,
            BAIRRO_NOME: this.$store.state.neighborhood.name,
          });

          loadingController.dismiss();
          this.openToast(response.message);
        } else {
          loadingController.dismiss();

          this.openToast(response.message);
        }
      } catch (error) {
        loadingController.dismiss();

        this.openToast(
          "Erro ao confirmar seu pedido! Verifique todos os campos."
        );
      }
    },
    getPlatform() {
      if (isPlatform("capacitor")) {
        if (isPlatform("android")) {
          return 1;
        } else {
          return 2;
        }
      } else {
        return 4;
      }
    },
    async openConfirmOrder() {
      const modal = await modalController.create({
        component: ModalConfirmOrder,
        cssClass: "modal-confirm-order",
        componentProps: {
          payment: this.$store.state.cart.paymentMethod,
          address: this.$store.state.cart.address,
          data: this.$data,
          deliveryFee: this.moneyMask(this.productSelected.deliveryFee),
          total: this.moneyMask(this.getTotal),
        },
      });

      modal.onDidDismiss().then(async (res) => {
        if (res?.data?.confirmed) {
          await this.createOrder();
        }
      });

      await modal.present();
    },

    async changeProduct(index, product) {
      const modal = await modalController.create({
        component: ModalChangeProduct,
        cssClass: "custom-modal-full-transparent",
        componentProps: {
          title: product.name,
          inputs: [{ placeholder: "Observação" }],
          product: index,
          products: product,
        },
      });

      modal.present();
    },

    useCashback(checked) {
      const cart = this.$store.state.cart;

      if (cart.paymentMethod?.payment_method_id == 1) {
        this.resetPayment();
      }

      if (checked) {
        if (this.balance > cart.price) {
          this.walletUsed = cart.price;
        } else if (this.balance < cart.price) {
          this.walletUsed = this.balance;
        }
      } else {
        this.walletUsed = 0;
      }
    },
    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
    valueRound(value) {
      return Math.round(value * 100) / 100;
    },
    async removeCoupon() {
      const message = await this.$store.dispatch(
        typesModule.actions.SET_COUPON
      );

      if (this.$store.state.cart.paymentMethod?.payment_method_id == 1) {
        this.resetPayment();
      }

      this.openToast(message);
    },

    async openModalCoupom() {
      const phoneUser = this.$store.state.user.phone;

      if (!this.$store.state.user.token) {
        return this.openLoginModal();
      }

      if (!phoneUser) {
        return this.openAlertPhone();
      }

      if (!this.$store.state.user.phoneVerified) {
        this.showLoading("Valide seu telefone...");
        await sendValidationSMS();

        loadingController.dismiss();
        const modal = await modalController.create({
          component: ValidatePhoneVue,
          componentProps: { phone: phoneUser },
          cssClass: "custom-modal-full-transparent",
        });

        return modal.present();
      }

      const modal = await modalController.create({
        component: CouponsModal,
        cssClass: "custom-modal-full-transparent",
      });

      return modal.present();
    },

    async openToast(message, time = 2000) {
      const toast = await toastController.create({
        message: message,
        duration: time,
      });
      return toast.present();
    },
    async openPaymentMethodsModal() {
      if (!this.$store.state.user.token) {
        this.openLoginModal();
        return;
      }

      const modal = await modalController.create({
        component: PaymentMethods,
        cssClass: "custom-modal-full-transparent",
        componentProps: {
          payment: this.selectedPaymentMethod,
          walletUsed: this.walletUsed,
        },
      });

      modal.onDidDismiss().then((data) => {
        this.paymentData = data;
      });

      return modal.present();
    },
    async openDataInvoiceModal() {
      const modal = await modalController.create({
        component: DataInvoiceModal,
        cssClass: "custom-modal-full-transparent",
      });

      modal.onDidDismiss().then((data) => {
        this.$data.taxpayerNumber = data.data;
      });

      return modal.present();
    },
    async openLoginModal() {
      const modal = await modalController.create({
        component: Login as any,
        componentProps: { isModal: true },
        cssClass: "custom-modal-full-transparent",
      });

      return modal.present();
    },
    async openAlertPhone() {
      const alert = await alertController.create({
        header: "Informe seu celular",
        cssClass: "alert-input-phone",
        message:
          "O número do telefone é importante para contactá-lo.<br>Digite apenas números.",
        inputs: [
          {
            name: "phone",
            id: "phone",
            type: "tel",
            placeholder: "DDD+Telefone",
            attributes: {
              maxlength: 11,
              autocomplete: "tel-national",
            },
          },
        ],
        buttons: [
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              alert.dismiss();
            },
          },
          {
            text: "Enviar",
            handler: async (value) => {
              const response = await ChangePhoneNumber({
                phone: value.phone,
              });
              if (response) {
                this.$store.dispatch(typesModule.actions.UPDATE_USER, response);
              }
            },
          },
        ],
      });
      return alert.present();
    },
    async openDeliveryAddressModal() {
      if (!this.$store.state.user.token) {
        this.openLoginModal();
        return;
      }

      const modal = await modalController.create({
        component: DeliveryAddress,
      });

      modal.onDidDismiss().then((data) => {
        this.isWithdraw = data?.data?.isWithdraw;
        this.paymentData = data?.data?.confirm;
      });

      return await modal.present();
    },
    resetPayment() {
      this.$store.dispatch(
        typesModule.actions.SELECTED_PAYMENT_METHOD,
        undefined
      );
    },
  },
};
