
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  alertController,
  IonButton,
  IonIcon,
  IonSkeletonText,
  IonFooter,
  modalController,
  IonToolbar,
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import { pencilOutline, trashBinOutline } from "ionicons/icons";
import { ref } from "@vue/reactivity";
import { onUpdated } from "@vue/runtime-core";

import ManageAddressModal from "./ManageAddress.vue";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { useRouter } from "vue-router";

import getAddress from "@/data/providers/address/get-address";
import deleteAddress from "@/data/providers/address/delete-address";
export default {
  name: "Address",
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonButton,
    IonIcon,
    IonFooter,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonSkeletonText,
  },
  setup() {
    const loadingRef = ref(true);
    const addressRef = ref([]);
    const router = useRouter();

    const getAddressData = async () => {
      loadingRef.value = true;
      const addressResponse = await getAddress();

      addressRef.value = addressResponse;
      loadingRef.value = false;
    };

    onUpdated(getAddressData);
    return {
      loadingRef,
      addressRef,
      pencilOutline,
      trashBinOutline,
      getAddressData,
    };
  },

  methods: {
    async openModalAddAddress() {
      const modal = await modalController.create({
        component: ManageAddressModal,
      });

      modal.onDidDismiss().then((res) => {
        if (res?.data?.success) {
          this.getAddressData();
        }
      });

      return modal.present();
    },

    async editAddress(address) {
      const modal = await modalController.create({
        component: ManageAddressModal,
        componentProps: {
          address: address,
        },
      });

      modal.onDidDismiss().then((res) => {
        if (res?.data?.success) {
          this.getAddressData();
        }
      });

      return modal.present();
    },

    async alertDelete(id) {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Excluir endereço",
        message: "Tem certeza que deseja excluir esse endereço?",
        buttons: [
          {
            text: "Sim",
            handler: async () => {
              await deleteAddress(id);
              await this.getAddressData();
            },
          },
          {
            text: "Cancelar",
            role: "calcel",
          },
        ],
      });
      await alert.present();
    },
  },
};
