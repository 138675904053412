
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  modalController,
  IonCard,
} from "@ionic/vue";
import { defineComponent } from "vue";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";

export default defineComponent({
  name: "ChooseEmail",
  components: {
    IonContent,
    IonPage,
    HeaderWithBalance,
    IonList,
    IonItem,
    IonCard,
  },
  props: ["user"],
  methods: {
    goLogin(email) {
      modalController.dismiss(email);
    },

    formatEmail(email) {
      const emailTemp = email.split("@");
      return (
        emailTemp[0].substr(0, 3) +
        "***" +
        emailTemp[0].substr(-2, 2) +
        "@" +
        emailTemp[1]
      );
    },
  },
});
