
import { IonImg, IonIcon, IonBadge, IonCard, IonCardContent } from "@ionic/vue";
import { bicycle, card, timeOutline, repeat, star } from "ionicons/icons";

export default {
  name: "EstablishmentCard",
  components: { IonImg, IonIcon, IonBadge, IonCard, IonCardContent },
  props: ["establishment"],
  setup() {
    return {
      bicycle,
      card,
      timeOutline,
      repeat,
      star,
    };
  },
  methods: {
    formatRating(rating) {
      const format = rating.toFixed(1).replace(".", ",");
      return format;
    },
    formatCurrency(currency) {
      const format = "R$ " + currency.toFixed(2).replace(".", ",");
      return format;
    },
  },
};
