
import { optionsOutline } from "ionicons/icons";
import {
  IonPage,
  IonContent,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonSkeletonText,
} from "@ionic/vue";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

import Categories from "@/data/providers/categories";
import { useStore } from "vuex";
import typesModule from "@/data/modules/typesModule";

export default {
  name: "Search",
  components: {
    HeaderWithBalance,
    IonContent,
    IonPage,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonSkeletonText,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const categoriesRef = ref([]);
    const loadingRef = ref(true);

    store.dispatch(typesModule.actions.RENDERING_TABS, "/tabs/search");

    const getCategories = async () => {
      loadingRef.value = true;

      const categoriesData = await Categories();

      categoriesRef.value = categoriesData;

      loadingRef.value = false;
    };

    const getEstablishmentsCategories = async (category) => {
      store.dispatch(typesModule.actions.SET_CATEGORY, category);
      router.push({
        name: "simple-search",
        params: { category: category },
      });
    };

    const getEstablishmentName = async (searchName) => {
      store.dispatch(typesModule.actions.SET_SEARCH_NAME, searchName);
      router.push({
        name: "search-list",
        params: { searchName: searchName },
      });
    };

    const goFilters = async () => {
      router.push("/filters");
    };

    onMounted(() => {
      document.title = "Buscar em TheLivery";

      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Entregamos tudo o que você precisa com dinheiro de volta (cashback). São milhares de opções de delivery: do pãozinho ao sushi e do sabonete a remédios."
        );

      getCategories();
    });

    return {
      loadingRef,
      categoriesRef,
      getCategories,
      goFilters,
      optionsOutline,
      getEstablishmentsCategories,
      getEstablishmentName,
    };
  },

  data: function () {
    return {
      searchName: "",
    };
  },
};
