<template>
  <ion-page>
    <ion-content>
      <basic-header title="Sorteio" />
      <div class="circular-border">
        <raffle-detail-skeleton v-if="loading"></raffle-detail-skeleton>
        <div v-else>
          <section>
            <ion-grid class="ion-no-margin">
              <ion-row>
                <ion-col
                  class="col-img"
                  size-sm="12"
                  size-xs="12"
                  size-md="12"
                  size-lg="6"
                  size-xl="6"
                >
                  <ion-thumbnail v-if="raffle.img" class="banner">
                    <ion-img
                      :src="`https://storage.googleapis.com/thelivery/img/banners/${raffle.img}`"
                    ></ion-img>
                  </ion-thumbnail>
                </ion-col>
                <ion-col
                  v-if="!raffle.isEntrant"
                  class="box-list"
                  size-md="12"
                  size-sm="12"
                  size-xs="12"
                  size-lg="6"
                  size-xl="6"
                >
                  <h3>Como participar</h3>
                  <ion-row
                    v-for="howParticipate in raffle.howToEnter"
                    :key="howParticipate.id"
                  >
                    <ion-col size="12">
                      <h5>{{ howParticipate.title }}</h5>
                    </ion-col>
                    <ion-col size="12">
                      <p>
                        {{ howParticipate.description }}
                      </p>
                    </ion-col>
                  </ion-row>
                </ion-col>
                <ion-col
                  v-else
                  class="box-list participation"
                  size-md="12"
                  size-sm="12"
                  size-xs="12"
                  size-lg="6"
                  size-xl="6"
                >
                  <ion-row @click="luckyNumbers">
                    <ion-icon :icon="ticket" />
                    <div>
                      <h5>Ver números da sorte</h5>
                    </div>
                  </ion-row>
                  <ion-row>
                    <ion-icon :icon="trophy" />
                    <div>
                      <h5>Resultado</h5>
                      <p v-if="raffle.winner">
                        <span v-if="raffle.isTheWinner"
                          >Parabéns! Você ganhou o sorteio através do número #{{
                            raffle.winner.coupon
                          }}.</span
                        >
                        <span v-else
                          >Infelizmente, não foi desta vez. O número sorteado
                          foi #{{ raffle.winner.coupon }}.</span
                        >
                      </p>
                      <p v-else>
                        O resultado será divulgado em
                        {{ formatDate(raffle.raffle_date) }}.
                      </p>
                    </div>
                  </ion-row>
                  <ion-row v-if="raffle.active" @click="shareRaffle">
                    <ion-icon :icon="rocket" />
                    <div>
                      <h5>Compartilhar com amigos</h5>
                      <p>Ganhe mais números da sorte.</p>
                    </div>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-grid>
          </section>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar v-if="loading" class="toolbarSkeleton">
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text
          class="toolbarButtonSkeleton"
          animated
        ></ion-skeleton-text>
      </ion-toolbar>
      <ion-toolbar v-else>
        <p v-if="!raffle.isEntrant">
          Ao se inscrever você aceita as
          <span @click="rulesModal">Regras da Promoção</span>.
        </p>
        <p v-else>
          Confira as <span @click="rulesModal">Regras da Promoção</span>.
        </p>
        <ion-button
          v-if="!raffle.isEntrant"
          shape="round"
          color="primary"
          expand="full"
          :disabled="!raffle.active || raffle.winner_id"
          @click="wantParticipate"
        >
          Quero Participar
        </ion-button>
        <ion-button v-else shape="round" disabled color="success" expand="full">
          Inscrição Realizada
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonThumbnail,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonToolbar,
  IonButton,
  IonIcon,
  modalController,
  toastController,
  loadingController,
  IonSkeletonText,
} from "@ionic/vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import { ticket, trophy, rocket } from "ionicons/icons";
import LuckyNumbersModal from "@/components/modals/raffles/LuckyNumbersModal.vue";
import RulesModal from "@/components/modals/raffles/RulesModal.vue";
import Raffle from "@/data/providers/raffles/raffle";
import RaffleParticipate from "@/data/providers/raffles/participate";
import Login from "@/views/Login/Login.vue";
import RaffleDetailSkeleton from "@/components/skeletonComponents/RaffleDetailsSkeleton.vue";
import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";

export default {
  name: "Details",
  components: {
    IonPage,
    IonContent,
    BasicHeader,
    IonThumbnail,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    IonFooter,
    IonToolbar,
    IonButton,
    IonIcon,
    IonSkeletonText,
    RaffleDetailSkeleton,
  },
  setup() {
    return {
      ticket,
      trophy,
      rocket,
    };
  },
  data: () => ({
    raffle: Object,
    loading: true,
    refer: "",
  }),
  async mounted() {
    await this.getRaffle();

    document.title = `Participe de ${this.raffle.title} - TheLivery`;

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        `Faça seu pedido e participe de ${this.raffle.title}. Sorteio. Prêmios.`
      );
  },
  methods: {
    async getRaffle() {
      this.loading = true;
      this.raffle = await Raffle(this.$route.params.raffleSlug);
      this.refer = this.$route.params.reference;
      this.loading = false;
    },
    async wantParticipate() {
      const loading = await loadingController.create();

      loading.present();

      if (!this.$store.state.user.token) {
        loadingController.dismiss();
        return this.openLoginModal();
      }

      try {
        await RaffleParticipate(this.raffle.id, this.refer);

        this.raffle.isEntrant = true;
        loadingController.dismiss();
      } catch (error) {
        loadingController.dismiss();
        if (error.response?.data) {
          this.createToast(error.response?.data?.message);
        }
      }
    },
    async luckyNumbers() {
      const modal = await modalController.create({
        component: LuckyNumbersModal,
        componentProps: {
          raffle: this.raffle.id,
        },
      });
      modal.present();
    },
    async rulesModal() {
      const modal = await modalController.create({
        component: RulesModal,
        componentProps: {
          rules: this.raffle.rules,
        },
      });
      modal.present();
    },
    createToast(message) {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    },
    formatDate(date) {
      const jsDate = new Date(date);

      return jsDate.toLocaleDateString("pt-BR");
    },
    async openLoginModal() {
      const modal = await modalController.create({
        component: Login,
        componentProps: { isModal: true },
        cssClass: "custom-modal-full-transparent",
      });

      modal.onDidDismiss().then((response) => {
        if (response.data?.login) {
          this.getRaffle();
        }
      });

      return modal.present();
    },
    async shareRaffle() {
      const url = `${this.$store.state.others.appOptions.base_url}/sorteios/${this.raffle.slug}/refer/${this.$store.state.user.slug}`;

      if (Capacitor.isNativePlatform()) {
        await Share.share({
          text: `Olha esse sorteio que o TheLivery está fazendo! <3`,
          url,
        });
      } else {
        navigator.clipboard.writeText(url),
          toastController
            .create({
              message: "Endereço copiado",
              duration: 2000,
            })
            .then((toast) => toast.present());
      }
    },
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  margin: 0;
  height: auto;
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .banner {
    margin: 0 auto;
    max-width: 576px;
  }
}
.banner ion-img {
  border-radius: 15px;
}

h3 {
  font-size: 18px;
  color: #58555d;
}

.box-list ion-row {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
}
.box-list ion-col {
  padding: 0;
}
ion-grid ion-row h5 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
ion-grid ion-row p {
  margin: 1px 0;
  font-size: 12px;
  color: #4f4f4f;
}

ion-grid {
  padding: 0px;
}
.participation ion-row {
  display: flex;
  align-items: center;
}
.participation ion-icon {
  font-size: 32px;
  margin-right: 12px;
  color: var(--ion-color-primary);
}

ion-footer p {
  text-align: center;
  font-size: 12px;
  margin: 0 0 16px 0;
}
ion-footer p span {
  color: var(--ion-color-primary);
  font-weight: 500;
}

.toolbarSkeleton {
  padding: 0 16px;
}
.toolbarSkeleton .toolbarButtonSkeleton {
  height: 45px;
  border-radius: 50px;
  margin-top: 16px;
}
</style>
