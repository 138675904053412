<template>
  <ProfileInfo v-if="isAuthenticatedRef" />
  <Login v-else />
</template>

<script>
import ProfileInfo from "../Profile/ProfileInfo.vue";
import Login from "../Login/Login.vue";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { watch } from "@vue/runtime-core";

export default {
  name: "Profile",
  components: { ProfileInfo, Login },
  setup: () => {
    const store = useStore();

    const isAuthenticatedRef = ref(false);

    isAuthenticatedRef.value = !!store.state.user.token;

    watch(store.state.user, () => {
      isAuthenticatedRef.value = !!store.state.user.token;
    });

    return {
      isAuthenticatedRef,
    };
  },
  methods: {
    ionViewWillEnter() {
      document.title = "Perfil em TheLivery";

      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Entregamos tudo o que você precisa com dinheiro de volta (cashback). São milhares de opções de delivery: do pãozinho ao sushi e do sabonete a remédios."
        );
    },
  },
};
</script>
