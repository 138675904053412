
import {
  IonIcon,
  IonCard,
  IonCardContent,
  IonLabel,
  IonCardHeader,
  IonButton,
} from "@ionic/vue";
import { ticketOutline } from "ionicons/icons";

export default {
  name: "CouponCard",
  components: {
    IonIcon,
    IonCard,
    IonCardContent,
    IonLabel,
    IonCardHeader,
    IonButton,
  },
  props: ["coupon"],
  setup() {
    return {
      ticketOutline,
    };
  },
  methods: {
    formatDate(date) {
      const jsDate = new Date(date);

      return jsDate.toLocaleDateString("pt-BR");
    },
  },
};
