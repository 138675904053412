
import { IonList, IonItem, IonRadio, IonRadioGroup } from "@ionic/vue";

export default {
  name: "SelectUniqueSuboption",
  components: { IonList, IonItem, IonRadio, IonRadioGroup },
  props: ["suboptions", "alreadySelected", "mandatory"],
  emits: ["selectSuboption", "preselectSuboption"],
  data: function () {
    return {
      defaultValue: "",
    };
  },
  mounted() {
    const selectedSuboption = this.alreadySelected?.[0];

    if (selectedSuboption) {
      const selected = this.suboptions.find(
        (suboption) => suboption.id == selectedSuboption.id
      );

      this.defaultValue = selected?.name || "";
      this.$emit("preselectSuboption", [selected]);
    } else if (this.suboptions.length == 1 && this.mandatory) {
      this.defaultValue = this.suboptions[0]?.name || "";
      this.$emit("preselectSuboption", [this.suboptions[0]]);
    }
  },
  methods: {
    moneyMask(value) {
      return `R$ ${value.toFixed(2).replace(".", ",")}`;
    },
    clickSuboption(event) {
      let value = event.detail.value;
      if (!value) {
        value = "";
      }
      const selected = this.$props.suboptions.find(
        (suboption) => suboption.name == value
      );
      this.$emit("selectSuboption", [selected]);
    },
  },
};
