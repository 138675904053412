
import {
  IonHeader,
  IonContent,
  IonSkeletonText,
  IonPage,
  IonList,
  IonItem,
  modalController,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonButton,
  IonFooter,
  IonToolbar,
  toastController,
} from "@ionic/vue";
import SimpleHeader from "@/components/headers/SimpleHeader.vue";
import { homeSharp, square, triangle } from "ionicons/icons";
import getOnlineNeighborhoods from "@/data/providers/neighborhoods";
import getOnlineNeighborhoodsByEstablishment from "@/data/providers/neighborhoods-by-establishment";
import { onMounted, ref, defineComponent } from "vue";
import { useStore } from "vuex";
import typesModule from "@/data/modules/typesModule";

export default defineComponent({
  name: "Neighborhoods",
  components: {
    IonPage,
    SimpleHeader,
    IonHeader,
    IonSkeletonText,
    IonContent,
    IonList,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonSearchbar,
    IonButton,
    IonFooter,
    IonToolbar,
  },
  props: {
    cityId: Number,
    citySlug: String,
    cityName: String,
    establishmentId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const neighborhoodsRef: any = ref([]);
    const loadingRef = ref(true);
    let neighborhoodsResponse: any;

    const getNeighborhoods = async () => {
      loadingRef.value = true;

      if (props.establishmentId) {
        neighborhoodsResponse = await getOnlineNeighborhoodsByEstablishment(
          props.establishmentId
        );
      } else {
        neighborhoodsResponse = await getOnlineNeighborhoods(props.cityId);
      }

      neighborhoodsRef.value = neighborhoodsResponse;

      loadingRef.value = false;
    };

    const filterList = async (evt) => {
      neighborhoodsRef.value = neighborhoodsResponse;
      const searchTerm = evt.srcElement.value;

      if (!searchTerm) {
        return;
      }

      neighborhoodsRef.value = neighborhoodsResponse.filter((currentNeigh) => {
        if (currentNeigh.name && searchTerm) {
          return (
            currentNeigh.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >
            -1
          );
        }
      });
    };

    onMounted(getNeighborhoods);

    return {
      loadingRef,
      neighborhoodsRef,
      homeSharp,
      square,
      triangle,
      store,
      filterList,
    };
  },
  data() {
    return {
      neighborhoodSelected: undefined,
    };
  },
  methods: {
    markNeighborhood(neighborhood) {
      this.neighborhoodSelected = neighborhood;
    },
    async selectNeighborhood() {
      if (!this.neighborhoodSelected) {
        const toast = await toastController.create({
          message: "Por favor, selecione um bairro antes de prosseguir",
          duration: 2000,
        });
        toast.present();
        return;
      }

      this.$store.dispatch(typesModule.actions.CHANGE_NEIGHBORHOOD, {
        ...this.neighborhoodSelected,
        cityId: this.$props.cityId,
        citySlug: this.$props.citySlug,
        cityName: this.$props.cityName,
      });

      if (this.establishmentId) {
        this.$store.dispatch(
          typesModule.actions.SET_DELIVERY_FEE,
          this.neighborhoodSelected.deliveryFee
        );

        modalController.dismiss({ success: true });

        return;
      }

      modalController.dismiss();
    },
    hasCityAndNeigh() {
      if (
        this.store.state.neighborhood.cityId &&
        this.store.state.neighborhood.id
      ) {
        return true;
      }

      return false;
    },
  },
});
